import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Contacts: React.FC = () => {
  return (
    <Container className="contacts text-full-width text-b my-5 fs-6">
      <h1 className="">Контакты</h1>
      <Row>
        <Col>
          <p className="fw-bold">Компания</p>
          <div className="d-flex align-items-center mb-3">
            <img width={70} height={70} src="/images/negotsiantlogo.png" alt="" />
            <h3 className="logo-label m-0 fw-bold">ООО "НЕГОЦИАНТ"</h3>
          </div>
          <p className="fw-bold">Адрес:</p>
          <p>660022, Красноярский край, г.Красноярск, ул.Партизана Железняка, д.19В , пом.127</p>
          <p className="fw-bold">Часы работы офиса:</p>
          <p>с 9:00 до 18:00 с понедельника по пятницу</p>
          <p className="fw-bold">Техническая поддержка клиентов:</p>
          <p>
            Телефон:
            <a href="tel: +7(391)2030015"> +7(391)203-00-15</a>
          </p>
          <p>
            Email:
            <a href="mailto: support@neg24.ru"> support@neg24.ru</a>
          </p>
          <p className="fw-bold">По вопросам сотрудничества:</p>
          <p>
            Телефон:
            <a href="tel: +7(391)2030015"> +7(391)203-00-15</a>
          </p>
          <p>
            Email:
            <a href="mailto: info@neg24.ru"> info@neg24.ru</a>
          </p>
          <p className="fw-bold">Реквизиты:</p>
          <p>ИНН: 2465328990</p>
          <p>Код причины постановки(КПП): 246501001</p>
          <p>ОГРН: 1192468041941</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Contacts;
