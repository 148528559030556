import { Outlet, useLocation } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";

export default function MainLayout() {
  const location = useLocation();
  return (
    <div className="wrapper">
      <Header />
      <Outlet />
      {location.pathname === "/" && <Footer />}
    </div>
  );
}
