import { AxiosResponse } from "axios";

import { SHA256 } from "../utils/SHA256";
import config from "../config";
import api from "../axios/config.axios";

export default class AuthService {
  static async registration(
    email: string,
    password: string,
    name: string,
    surname: string,
    phone: string,
    placeid: string
  ): Promise<AxiosResponse> {
    const saltPass = SHA256(password + config.SALT);
    return api.post("auth/registration", { email, password: saltPass, name, surname, phone, placeid });
  }
  static async login(email: string, password: string, placeid: string): Promise<AxiosResponse> {
    const saltPass = SHA256(password + config.SALT);
    return api.post("auth/login", { email, password: saltPass, placeid });
  }
  static async logout(): Promise<AxiosResponse<void>> {
    return api.post<void>("auth/logout");
  }
  static async refresh(): Promise<AxiosResponse> {
    return api.post("auth/refresh");
  }
  static async checkLogin(): Promise<AxiosResponse> {
    return api.get("auth/checklogin");
  }
}
