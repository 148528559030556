import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import nomenclatureNoImg from "../../assets/img/nomenclature_no_image.png";
import NomenclatureService from "../../services/NomenclatureService";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import config from "../../config";
import { Button, Carousel, CarouselItem, Col, Table } from "react-bootstrap";
import Loader from "../../components/Loaders/WaterWaveRing/WaterWaveRing";
import useUserStore from "../../zustand/user-store";
import usePlaceStore from "../../zustand/place-store";
import useCurrencyStore from "../../zustand/currency-store";
import useCartStore from "../../zustand/cart-store";
import useLoginDialogStore from "../../zustand/login-modal-store";
import showToast from "../../utils/toast";
import { NomenclatureExTypes } from "../../types/nomenclature";

export function NomenclaturePage() {
  const { openDialog } = useLoginDialogStore((state) => state);
  const navigate = useNavigate();
  let { nomenclatureid }: { nomenclatureid?: string } = useParams();
  const { place } = usePlaceStore((state) => state);
  const { user } = useUserStore((state) => state);
  const { cart, insert: cartInsert } = useCartStore((state) => state);
  const { currency } = useCurrencyStore((state) => state);
  const [loading, setLoading] = useState(false);
  const [nomenclature, setNomenclature] = useState<NomenclatureExTypes | null>(null);

  const onClickAdd = async (): Promise<void> => {
    if (!place || !nomenclature) return;
    if (!user.userprofileid) return openDialog();
    cartInsert(nomenclature.nomenclatureid);
  };
  const onLoginClick = () => {
    openDialog();
  };

  const cartItemExist = (): boolean => {
    if (!nomenclature) return false;
    const item = cart.find((item) => item.nomenclatureid === nomenclature.nomenclatureid);
    if (item) return true;
    else return false;
  };

  useEffect(() => {
    setLoading(true);
    if (!place || !nomenclatureid) return;
    NomenclatureService.getOne(nomenclatureid, place.placeid)
      .then((res) => setNomenclature(res.data))
      .catch((e) => showToast("error", e.response.data.message))
      .finally(() => setLoading(false));
  }, [place, nomenclatureid]);

  if (loading) {
    return <Loader />;
  }

  if (nomenclature)
    return (
      <>
        <Container className="mt-5">
          <Row>
            <Col xs={12} className="d-sm-none ">
              <Button className="style-btn-action w-auto col-3 mb-5" onClick={() => navigate(-1)}>
                ← Назад
              </Button>
            </Col>
            <Col xs={12} sm={9} md={10} lg={10} xl={11} className="">
              <h1 className="mb-0">{nomenclature.name}</h1>
              <p>{nomenclature.namefull}</p>
            </Col>
            <Col xs={12} sm={3} md={2} lg={2} xl={1} className="d-none d-sm-block text-end">
              <Button className="style-btn-action w-auto col-3 mb-5" onClick={() => navigate(-1)}>
                ← Назад
              </Button>
            </Col>
          </Row>
          {/* <hr className="text-dark" /> */}
          <Row className="justify-content-between">
            <Col xs={12} sm={12} md={12} lg={5} xl={5} className="">
              {nomenclature.images.length === 0 && (
                <div className="w-50 mx-auto">
                  <img className="d-block w-100" src={nomenclatureNoImg} alt={nomenclature.name} />
                </div>
              )}
              {nomenclature.images.length === 1 && (
                <div className="w-50 mx-auto">
                  <img
                    className="d-block w-100"
                    src={`${config.API_URL}/nomenclature/image/` + nomenclature.images[0].imageid}
                    alt={nomenclature.images[0].name}
                  />
                </div>
              )}
              {nomenclature.images.length > 1 && (
                <Carousel controls={false} variant="dark" className="mx-auto mb-5" data-bs-ride="carousel">
                  {nomenclature.images.map((item) => (
                    <CarouselItem className="carousel-inner" key={item.imageid}>
                      <div className="carousel-item active" data-bs-interval="5000">
                        <img className="d-block w-100 p-5" src={`${config.API_URL}/nomenclature/image/` + item.imageid} alt={item.name} />
                      </div>
                    </CarouselItem>
                  ))}
                </Carousel>
              )}
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              {nomenclature.price && (
                <Row
                  className="align-items-center py-3 px-3"
                  style={{
                    backgroundColor: "#3e4652",
                  }}
                >
                  {!user && (
                    <Col>
                      <Button className="style-btn-link fs-5" onClick={onLoginClick}>
                        В корзину
                      </Button>
                    </Col>
                  )}
                  {user && (
                    <Col>
                      {cartItemExist() && (
                        <Button className="buy-btn-in-cart fs-5" disabled>
                          В корзине
                        </Button>
                      )}
                      {!cartItemExist() && (
                        <Button className="style-btn-link fs-5" onClick={onClickAdd}>
                          В корзину
                        </Button>
                      )}
                    </Col>
                  )}
                  <Col>
                    <p className="m-0 fs-4 text-white text-end">
                      Цена:{" "}
                      <b className="text-white">
                        {nomenclature.price.toLocaleString()} {currency.nameshort}
                      </b>
                    </p>
                  </Col>
                </Row>
              )}
              <Row>
                {nomenclature.characteristics.length > 0 && (
                  <Table striped hover size="sm" className="mb-5">
                    <thead>
                      <tr>
                        <th scope="col">Параметры лицензии</th>
                        <th scope="col">Значение</th>
                      </tr>
                    </thead>
                    <tbody>
                      {nomenclature.characteristics.map((item: any, i) => (
                        <tr key={i}>
                          <td>{item.characteristicname}</td>
                          <td>{item.value1}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Row>
            </Col>
          </Row>
          <div className="nomenclature-description my-5 p-5">
            <h2>Описание:</h2>
            <div className="text-dark text-start fs-6" dangerouslySetInnerHTML={{ __html: nomenclature.description }}></div>
          </div>
        </Container>
      </>
    );
  return <Container className="text-center">Нет данных</Container>;
}
