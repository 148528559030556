import { AxiosResponse } from "axios";

import $api from "../axios/config.axios";

interface IPlace {
  placeid: string;
  name: string;
  currencyid: string;
  shopnamesys: string;
  namesys: string;
  isdefault: boolean;
}
interface IOAuth20 {
  oauth20passportid: string;
  shopid: string;
  shopname: string;
  clientid: string;
  secret: string;
  oauth20type: number;
}

export class ShopService {
  static async getShopIdByNamesys(shopnamesys: string): Promise<AxiosResponse<any>> {
    return await $api.get<any>(`shop/shopid`, { params: { shopnamesys } });
  }
  //
  static async getAllShopCurrency(): Promise<AxiosResponse<any[]>> {
    return await $api.get<any[]>(`shop/currency`);
  }
  // Получение всех методов OAuth2.0 авторизации для магазина
  static async getAllOAuth20ByShop(): Promise<AxiosResponse<IOAuth20[]>> {
    return await $api.get<any[]>(`shop/oauth20`);
  }
}
