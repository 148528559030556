import { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router";
import usePlaceStore from "../../zustand/place-store";
import config from "../../config";
import createSocket from "../../lib/socket";
import { InvoiceService } from "../../services/InvoiceService";
import useSocket from "../../utils/useSocket";
import showToast from "../../utils/toast";
import Spinner from "../../components/Loaders/Spinner/Spinner";
import s from "./DownloadInvoiceBill.module.scss";

export enum BillStatus {
  pending,
  ready,
  error,
}

export default function SuccessPay() {
  let { invoiceid }: { invoiceid?: string } = useParams();
  const { place } = usePlaceStore((state) => state);

  const [billStatus, setBillStatus] = useState<BillStatus>(BillStatus.pending);

  useSocket(function (data: { invoiceid: string }) {
    // слушаем оповещение с сервера что счет сформирован и его можно скачать
    console.log({ data });
    setBillStatus(BillStatus.ready);
    localStorage.removeItem(`${config.API_SHOP}-invoiceid`);
  });

  useEffect(() => {
    if (!place || !invoiceid) return;
    InvoiceService.create(invoiceid, place.placeid)
      .then((res) => {
        const socket = createSocket();
        socket.emit("bill-status", { invoiceid });
        setTimeout(() => {
          if (BillStatus.ready) return;
          setBillStatus(BillStatus.error);
        }, 5000);
      })
      .catch((e) => showToast("error", e.response.data.message));
  }, [place, invoiceid]);

  if (!invoiceid) return null;
  console.log({ billStatus });

  return (
    <div className={s.wrapper}>
      {billStatus === BillStatus.pending && (
        <div className={s.card}>
          <div className={s.label}>
            <h3 className="m-0">Подождите. Счет формируется</h3>
          </div>
          <div className={s.status}>
            <Spinner />
          </div>
        </div>
      )}

      {billStatus === BillStatus.ready && (
        <div className={s.card}>
          <div className={s.label}>
            <h3 className="m-0">Можно загрузить</h3>
          </div>
          <div className={s.status}>
            <a className={s.download_link} href={`${config.API_URL}/invoice/bill/${invoiceid}`} target="_blank" download rel="noreferrer">
              Скачать счет
            </a>
          </div>
        </div>
      )}
      {billStatus === BillStatus.error && (
        <div className={s.card}>
          <div className={s.label}>
            <h3 className="m-0">Что-то пошло не так</h3>
          </div>
          <div className={s.status}>
            <a className={s.return_link} href={`${config.API_URL}/invoice/bill/${invoiceid}`} target="_blank" download rel="noreferrer">
              Вернуться к заказу
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
