import React, { useEffect, useState } from "react";
import { InvoiceService } from "../../services/InvoiceService";
import Container from "react-bootstrap/Container";
import InvoiceItem from "./InvoiceItem";
import EmptyBox from "../../components/EmptyBox";
import { Col, Row } from "react-bootstrap";
import Loader from "../../components/Loaders/WaterWaveRing/WaterWaveRing";
import { Pagination } from "../../components/Pagination";

export default function InvoicePage() {
  const limit = 10;
  const [offset, setOffset] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [totalcount, setTotalСount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [items, setItems] = useState<any[]>([]);

  const pageCount = Math.floor(totalcount / limit) + (totalcount % limit ? 1 : 0);

  useEffect(() => {
    InvoiceService.getAllHistory(0, offset, limit)
      .then((res) => {
        setItems(res.data.items);
        setTotalСount(res.data.totalCount);
      })
      .catch((e) => {
        alert("Error");
      })
      .finally(() => setLoading(false));
  }, [loading, offset]);

  const onChangePage = (page: number) => {
    setCurrentPage(page);
    setOffset(page * limit - limit);
  };

  if (loading) return <Loader />;
  if (items.length === 0)
    return (
      <Container>
        <EmptyBox />
      </Container>
    );
  return (
    <Container className="my-5">
      <div className="d-none d-md-block">
        <h1>Мои заказы</h1>
        <hr className="text-dark" />
      </div>
      <div className="accordion open accordion-flush" style={{ border: "2px solid #00b4b6" }}>
        <div className="accordion-item" style={{ borderBottom: "2px solid grey" }}>
          <Row
            className="row accordion-header-invoice justify-content-between align-items-center text-center w-100"
            style={{ paddingRight: "40px", paddingLeft: "20px" }}
          >
            <Col xs={1} sm={1} md={1} lg={1} xl={1} className="p-0">
              Номер
            </Col>
            <Col xs={3} sm={4} md={6} lg={5} xl={4} className="p-0">
              Статус
            </Col>
            <Col xs={1} sm={1} md={1} lg={1} xl={1} className="p-0">
              Кол-во
            </Col>
            <Col xs={3} sm={2} md={2} lg={2} xl={1} className="p-0 text-end">
              Стоимость
            </Col>
            <Col lg={2} xl={4} className="d-none p-0 d-lg-block">
              Дата создания
            </Col>
          </Row>
        </div>

        {items.map((invoice) => (
          <InvoiceItem invoice={invoice} key={invoice.invoiceid}></InvoiceItem>
        ))}
      </div>

      {pageCount > 1 && (
        <Container className="p-4">
          <Pagination currentPage={currentPage} onChangePage={onChangePage} pageCount={pageCount} />
        </Container>
      )}
    </Container>
  );
}
