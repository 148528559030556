import { create } from "zustand";

import {
  GoodsInvoiceTypes,
  InvoiceBuyerType,
  InvoiceExTypes,
  InvoicePaymentMethod,
  InvoicePaymentType,
  InvoicePickType,
} from "../types/invoice";
import { InvoiceService } from "../services/InvoiceService";

export type InvoiceDeliveryInfo = {
  invoiceId: string;
  deliveryInvoiceId: string | null;
  officeId: string | null;
  expectedDeliveryDate: Date | null;
  totalSum: number;
};

interface InvoiceState {
  invoiceState: InvoiceExTypes | null;
}

interface InvoiceAction {
  // Invoice
  getOne: (invoiceid: string) => Promise<InvoiceExTypes & GoodsInvoiceTypes>;
  init: () => Promise<string>;

  setInvoiceState: (invoice: InvoiceExTypes) => void;

  // Buyer
  updateBuyerType: (invoiceid: string, buyerType: InvoiceBuyerType) => Promise<void>;
  updateBuyerOrganization: (invoiceid: string, organizationid: string) => Promise<void>;

  // // Pick
  // updatePickType: (invoiceid: string, pickType: InvoicePickType) => Promise<void>;
  // updatePickDeliveryAddress: (invoiceid: string, addressid: string) => Promise<void>;
  // updatePickPickUpPoint: (invoiceid: string, pickUpPointId: string) => Promise<void>;
  // updatePickSelfPickUpPoint: (invoiceid: string, pickUpPointId: string) => Promise<void>;

  // Payment
  updatePaymentType: (invoiceid: string, paymentType: InvoicePaymentType) => Promise<void>;
  updatePaymentMethod: (invoiceid: string, paymentmethod: InvoicePaymentMethod) => Promise<void>;
}

const useInvoiceStore = create<InvoiceState & InvoiceAction>()((set, get) => ({
  invoiceState: null,
  invoiceDeliveryInfo: null,

  // Invoice
  getOne: async (invoiceid: string) => {
    const res = await InvoiceService.getOne(invoiceid);
    return res.data;
  },

  init: async (): Promise<string> => {
    const res = await InvoiceService.initInvoice();
    return res.data;
  },

  setInvoiceState: (invoice: InvoiceExTypes) => {
    set({ invoiceState: invoice });
  },

  // Buyer
  updateBuyerType: async (invoiceid: string, buyerType: InvoiceBuyerType) => {
    await InvoiceService.updateBuyerType(invoiceid, buyerType);
  },
  updateBuyerOrganization: async (invoiceid: string, organizationid: string) => {
    await InvoiceService.updateBuyerOrganization(invoiceid, organizationid);
  },

  // // Pick
  // updatePickType: async (invoiceid: string, deliveryType: InvoicePickType) => {
  //   await InvoiceService.updatePickType(invoiceid, deliveryType);
  // },
  // updatePickDeliveryAddress: async (invoiceid: string, addressid: string) => {
  //   await InvoiceService.updatePickDeliveryAddress(invoiceid, addressid);
  // },
  // updatePickPickUpPoint: async (invoiceid: string, pickuppointid: string) => {
  //   await InvoiceService.updatePickPickUpPoint(invoiceid, pickuppointid);
  // },
  // updatePickSelfPickUpPoint: async (invoiceid: string, pickuppointid: string) => {
  //   await InvoiceService.updatePickSelfPickUp(invoiceid, pickuppointid);
  // },

  // Payment
  updatePaymentType: async (invoiceid: string, paymentType: InvoicePaymentType) => {
    await InvoiceService.updatePaymentType(invoiceid, paymentType);
  },
  updatePaymentMethod: async (invoiceid: string, paymentMethod: InvoicePaymentMethod) => {
    await InvoiceService.updatePaymentOnlineMethod(invoiceid, paymentMethod);
  },
}));
export default useInvoiceStore;
