import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import EmptyBox from "../../components/EmptyBox";
import Loader from "../../components/Loaders/WaterWaveRing/WaterWaveRing";
import ShowcaseDownloadService, { ShowcaseDownload } from "../../services/ShowcaseDownloadService";
import ShowCaseDownloadItem from "./ShowCaseDownloadItem";

const ShowCaseDownloadList: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState([] as ShowcaseDownload[]);
  const [error, setError] = React.useState();

  const effectFunc = () => {
    setLoading(true);
    setError(undefined);
    ShowcaseDownloadService.getAll()
      .then((res) => {
        setItems(res.data.items);
      })
      .catch((e: any) => {
        if (e.response) {
          setError(e.response.data);
        } else {
          console.log("Error", e.message);
          setError(e.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  React.useEffect(effectFunc, []);
  if (loading) return <Loader />;

  if (error) return <Container className="text-center">{error}</Container>;
  if (items.length === 0) return <EmptyBox />;
  return (
    <Container className="showcasedownload my-5 text-b">
      <Row className="d-none d-md-block">
        <Col>
          <div>
            <h1>Загрузки</h1>
          </div>
        </Col>
      </Row>
      <hr className="text-dark d-none d-md-block" />
      {items.map((item, index) => (
        <Row key={item.showcasedownloadid} className="mb-5">
          <ShowCaseDownloadItem {...item} isright={index % 2 === 1} />
          <hr />
        </Row>
      ))}
    </Container>
  );
};

export default ShowCaseDownloadList;
