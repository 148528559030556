import { ChangeEvent, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import OrganizationService, { Organization } from "../../../services/OrganizationService";
import OrganizationControl, { OrganizationControlState } from "../../OrganizationPage/OrganizationControl";
import useInvoiceStore from "../../../zustand/invoice-store";
import downloads from "../../../assets/img/svg/downloads.svg";
import showToast from "../../../utils/toast";

enum Status {
  list,
  add,
}
export default function OrganizationOrder() {
  const navigate = useNavigate();

  const { invoiceState, setInvoiceState, updateBuyerOrganization } = useInvoiceStore((state) => state);
  const [organizationId, setOrganizationId] = useState("");
  const [items, setItems] = useState([] as Organization[]);
  const [status, setStatus] = useState<Status>(Status.list);

  useEffect(() => {
    OrganizationService.organizationGet()
      .then((res) => setItems(res.data.items))
      .catch((e) => showToast("error", e.response.data.message));
  }, []);

  const onChangeOrganization = (e: ChangeEvent<HTMLSelectElement>) => {
    if (!invoiceState) return;
    setOrganizationId(e.target.value);
    updateBuyerOrganization(invoiceState.invoiceid, e.target.value);
    setInvoiceState({ ...invoiceState, organizationid: e.target.value });
  };

  const onSaveCallback = (organization: Organization) => {
    setItems([...items, organization]);
    setOrganizationId(organization.organizationid);
    setStatus(Status.list);
  };

  const onCancelCallback = () => {
    setStatus(Status.list);
  };

  const onFormBill = () => {
    if (!invoiceState) return;
    if (!invoiceState.invoiceid) return showToast("warning", "Заказ не инициализирован");
    if (!invoiceState.organizationid) return showToast("warning", "Компания покупатель не указана");
    navigate(`/download-invoice/${invoiceState.invoiceid}`, { replace: true });
    // window.open(`/download-invoice/${invoiceState.invoiceid}`);
  };

  return (
    <>
      {status === Status.list && (
        <>
          <h2>Оформить на:</h2>
          <select className="w-100 rounded p-2" value={organizationId} onChange={onChangeOrganization}>
            <option disabled value="">
              --Укажите юр. лицо--
            </option>
            {items.map((item: Organization) => (
              <option value={item.organizationid} key={item.organizationid}>
                {item.name}
              </option>
            ))}
          </select>
          <Button variant="outline-secondary" className="w-100 my-3 border" onClick={() => setStatus(Status.add)}>
            + Добавить новую организацию
          </Button>
          <p className="mt-5">
            Подтверждая заказ, Вы соглашаетесь с условиями{" "}
            <Link to="/privacy">
              <u>лицензионного соглашения</u>
            </Link>
            .
          </p>
          <Button className="style-btn-action px-5 py-3 w-100" onClick={onFormBill}>
            <img className="me-2" src={downloads} alt="download-price" />
            Сформировать счет
          </Button>
        </>
      )}
      {status === Status.add && (
        <OrganizationControl
          state={OrganizationControlState.add}
          organization={{} as Organization}
          saveCallback={onSaveCallback}
          cancelCallback={onCancelCallback}
        />
      )}
    </>
  );
}
