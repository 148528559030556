import { Container } from "react-bootstrap";

export default function PaymentTerms() {
  return (
    <Container className="text-full-width text-b my-5 fs-6">
      <h1>Правила оплаты</h1>
      <br />
      <p>К оплате принимаются платежные карты: VISA Inc, MasterCard WorldWide, МИР.</p>
      <p>Для оплаты товара банковской картой при оформлении заказа в интернет-магазине выберите способ оплаты: банковской картой.</p>
      <p>
        При оплате заказа банковской картой, обработка платежа происходит на авторизационной странице банка, где Вам необходимо ввести
        данные Вашей банковской карты:
      </p>
      <ul>
        <li>Тип карты</li>
        <li>Номер карты</li>
        <li>Срок действия карты (указан на лицевой стороне карты)</li>
        <li>Имя держателя карты</li>
        <li>CVC2/CVV код</li>
      </ul>
      <p>
        Если Ваша карта подключена к услуге 3D-Secure, Вы будете автоматически переадресованы на страницу банка, выпустившего карту, для
        прохождения процедуры аутентификации. Информацию о правилах и методах дополнительной идентификации уточняйте в Банке, выдавшем Вам
        банковскую карту.
      </p>
      <p>
        Безопасность обработки интернет-платежей через платежный шлюз банка гарантирована международным сертификатом безопасности PCI DSS.
        Передача информации происходит с применением технологии шифрования TLS. Эта информация недоступна посторонним лицам.
      </p>
      <br />
      <h1>Возврат товара</h1>
      <br />
      <p>Процедура возврата товара регламентируется статьей 26.1 федерального закона «О защите прав потребителей».</p>

      <ul>
        <li>Потребитель вправе отказаться от товара в любое время до его передачи, а после передачи товара - в течение семи дней</li>
        <li>
          Возврат товара надлежащего качества возможен в случае, если сохранены его товарный вид, потребительские свойства, а также
          документ, подтверждающий факт и условия покупки указанного товара
        </li>
        <li>
          Потребитель не вправе отказаться от товара надлежащего качества, имеющего индивидуально-определенные свойства, если указанный
          товар может быть использован исключительно приобретающим его человеком
        </li>
        <li>
          При отказе потребителя от товара продавец должен возвратить ему денежную сумму, уплаченную потребителем по договору, за
          исключением расходов продавца на доставку от потребителя возвращенного товара, не позднее чем через десять дней со дня
          предъявления потребителем соответствующего требования
        </li>
      </ul>
      <br />

      <h1>Отказ от услуги</h1>
      <br />
      <p>
        Право потребителя на расторжение договора об оказании услуги регламентируется статьей 32 федерального закона «О защите прав
        потребителей»
      </p>
      <ul>
        <li>
          Потребитель вправе расторгнуть договор об оказании услуги в любое время, уплатив исполнителю часть цены пропорционально части
          оказанной услуги до получения извещения о расторжении указанного договора и возместив исполнителю расходы, произведенные им до
          этого момента в целях исполнения договора, если они не входят в указанную часть цены услуги
        </li>
        <li>
          Потребитель при обнаружении недостатков оказанной услуги вправе по своему выбору потребовать:
          <ul>
            <li>Безвозмездного устранения недостатков</li>
            <li>Соответствующего уменьшения цены</li>
            <li>Возмещения понесенных им расходов по устранению недостатков своими силами или третьими лицами</li>
          </ul>
        </li>
        <li>
          Потребитель вправе предъявлять требования, связанные с недостатками оказанной услуги, если они обнаружены в течение гарантийного
          срока, а при его отсутствии в разумный срок, в пределах двух лет со дня принятия оказанной услуги
        </li>
        <li>
          Исполнитель отвечает за недостатки услуги, на которую не установлен гарантийный срок, если потребитель докажет, что они возникли
          до ее принятия им или по причинам, возникшим до этого момента
        </li>
      </ul>
    </Container>
  );
}
