import CartItem from "./CartItem";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import EmptyBox from "../../components/EmptyBox";
import cartclearall from "../../assets/img/svg/cartclearall.svg";
import useCartStore from "../../zustand/cart-store";
import useCurrencyStore from "../../zustand/currency-store";
import { useEffect, useState } from "react";

export function CartPage() {
  const { cart, check: checkCart, get: getCart, increment, decrement, remove, clear } = useCartStore((state) => state);
  const { currency } = useCurrencyStore((state) => state);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<string>("");

  useEffect(() => {
    checkCart().then(() => {
      getCart();
    });
  }, []);

  useEffect(() => {
    if (!cart) return;
    const count = cart.reduce((a, b) => a + b.cnt, 0);
    setTotalCount(count);
    const price = cart.reduce((a, b) => a + b.temporarysum, 0);
    const priceStr = price.toLocaleString();
    setTotalPrice(priceStr);
  }, [cart]);

  const onIncrement = (cartId: string) => {
    increment(cartId);
  };
  const onDecrement = (cartId: string) => {
    decrement(cartId);
  };
  const onDelete = (cartId: string) => {
    remove(cartId);
  };
  const onClear = () => {
    clear();
  };

  return (
    <>
      <Container className="container--cart my-5">
        <div className="cart">
          <Row className="align-items-center justify-content-between">
            <Col>
              <h1 className="d-none d-md-block">Корзина</h1>
            </Col>
            <Col className="text-end">
              <button style={{ border: "0", backgroundColor: "#f7f7f7", color: "gray" }} onClick={onClear}>
                Очистить
                <img src={cartclearall} alt="cartclearall" />
              </button>
            </Col>
          </Row>

          <div className="content__items">
            {cart.map((item: any) => (
              <CartItem
                item={item}
                key={item.nomenclatureid}
                handleIncrement={onIncrement}
                handleDecrement={onDecrement}
                handleDelete={onDelete}
              />
            ))}
          </div>
          {!(cart.length > 0) && (
            <EmptyBox message=" ">
              <p className="mb-0">
                В вашей корзине пусто,{" "}
                <Link to="/shop">
                  <Button className="style-btn-action rounded px-2 py-0">вернитесь в магазин</Button>
                </Link>
              </p>
              <p>чтобы добавить товар</p>
            </EmptyBox>
          )}
          <div className="row text-center justify-content-between my-3">
            <div className="col-6 col-xl-3 col-lg-3 col-md-4 col-sm-5 text-start">
              <p>
                Всего позиций: <b>{totalCount}</b>
              </p>
            </div>
            <div className="col-6 col-xl-4 col-lg-4 col-md-5 col-sm-6 text-end">
              <p>
                Сумма заказа:{" "}
                <b>
                  {totalPrice.toLocaleString()} {currency.nameshort}
                </b>
              </p>
            </div>
          </div>
          {cart.length > 0 && (
            <Link to="/order" className="btn style-btn-action col-12 text-white fs-4 py-3">
              Сформировать заказ
            </Link>
          )}
        </div>
      </Container>
    </>
  );
}
