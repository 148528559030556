// import Loadable from 'react-loadable';
import { Suspense, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "./pages/NotFound";
import InvoicePage from "./pages/InvoicePage";
import SuccessPay from "./pages/SuccessPay";
import Test from "./pages/Test";

import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/app.scss";
import MainLayout from "./layouts/MainLayout";

import Cookies from "js-cookie";
import UserProfilePage from "./pages/UserProfilePage";
import Contacts from "./pages/Contacts";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import dateLocalize from "./utils/dateLocalize";
import ShowCaseDownloadList from "./pages/ShowCaseDownload";
import ClientShop from "./pages/ClientShop";
import OrganizationList from "./pages/OrganizationPage";
import CartOrder from "./pages/CartPage/CartOrder";
import MainPage from "./pages/MainPage";
import News from "./pages/News";
// import ClientLicSchemeList from "./pages/ClientLicScheme";
import Terms from "./pages/Terms";
import Loader from "./components/Loaders/WaterWaveRing/WaterWaveRing";
import AuthService from "./services/AuthService";
import PaymentTerms from "./pages/PaymentTerms";
import { CartPage } from "./pages/CartPage/CartPage";
import useCartStore from "./zustand/cart-store";
import usePlaceStore from "./zustand/place-store";
import useCurrencyStore from "./zustand/currency-store";
import { NomenclaturePage } from "./pages/NomenclaturePage/NomenclaturePage";
import { ShopService } from "./services/ShopService";
import { Alert } from "react-bootstrap";
import config from "./config";
import useUserStore from "./zustand/user-store";
import DownloadInvoiceBill from "./pages/DownloadInvoiceBill/DownloadInvoiceBill";

dateLocalize();

function App() {
  const { place, getAll, getDefault, getOne } = usePlaceStore((state) => state);
  const { getCurrency, updateCurrency } = useCurrencyStore((state) => state);
  const { user, getUser, setInitUser } = useUserStore((state) => state);
  const { get: getCart, clear: clearCart } = useCartStore((state) => state);
  const [loading, setLoading] = useState(true);
  const [shopAvaivable, setShopAvailable] = useState<boolean>(false);

  // Проверка доступности магазмна
  useEffect(() => {
    if (!config.API_SHOP) return;
    const shopid = localStorage.getItem(`${config.API_SHOP}-shopid`);
    if (shopid && shopid !== "undefined") return setShopAvailable(true);
    setLoading(true);
    ShopService.getShopIdByNamesys(config.API_SHOP)
      .then((res) => {
        Cookies.set(`${config.API_SHOP}-shopid`, res.data);
        localStorage.setItem(`${config.API_SHOP}-shopid`, res.data);
        setShopAvailable(true);
      })
      .catch((e) => setShopAvailable(false))
      .finally(() => setLoading(false));
  }, [config.API_SHOP]);

  useEffect(() => {
    if (!shopAvaivable) return;
    const placeid = window.localStorage.getItem(`${config.API_SHOP}-placeid`);
    if (!placeid)
      getDefault().then((place) => {
        getCurrency(place.placeid);
        window.localStorage.setItem(`${config.API_SHOP}-placeid`, place.placeid);
      });
    if (placeid) {
      getOne(placeid);
      window.localStorage.setItem(`${config.API_SHOP}-placeid`, placeid);
      getCurrency(placeid);
    }
  }, [shopAvaivable]);

  useEffect(() => {
    const cookie_accessToken = Cookies.get(`${config.API_SHOP}-accessToken`);
    if (cookie_accessToken) localStorage.setItem(`${config.API_SHOP}-accessToken`, cookie_accessToken);
    Cookies.remove(`${config.API_SHOP}-accessToken`);
    const accessToken = localStorage.getItem(`${config.API_SHOP}-accessToken`);
    if (accessToken && !user.userprofileid) {
      AuthService.checkLogin()
        .then((res) => {
          getUser(res.data.userprofileid, res.data.email);
          getCart();
        })
        .catch((e) => {
          setInitUser();
          clearCart();
          alert("failed");
        })
        .finally(() => setLoading(false));
    } else if (loading) {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <Loader />;
  }
  if (!shopAvaivable) return <Alert variant="danger" />;
  return (
    <Routes>
      <Route path="order" element={<CartOrder />} />
      <Route path="/" element={<MainLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <MainPage />
            </Suspense>
          }
        />
        <Route path="showcasedownload" element={<ShowCaseDownloadList />} />

        <Route
          path="shop"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <ClientShop />
            </Suspense>
          }
        />
        <Route
          path="news"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <News />
            </Suspense>
          }
        />
        <Route
          path="nomenclature/:nomenclatureid"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <NomenclaturePage />
            </Suspense>
          }
        />

        <Route
          path="test"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <Test />
            </Suspense>
          }
        />

        <Route
          path="contacts"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <Contacts />
            </Suspense>
          }
        />
        <Route
          path="privacy"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <PrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path="terms"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <Terms />
            </Suspense>
          }
        />
        <Route
          path="payment-terms"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <PaymentTerms />
            </Suspense>
          }
        />

        {user && (
          <>
            <Route
              path="userprofile"
              element={
                <Suspense fallback={<div>Идёт загрузка...</div>}>
                  <UserProfilePage />
                </Suspense>
              }
            />
            <Route
              path="invoice"
              element={
                <Suspense fallback={<div>Идёт загрузка...</div>}>
                  <InvoicePage />
                </Suspense>
              }
            />
            <Route
              path="organization"
              element={
                <Suspense fallback={<div>Идёт загрузка...</div>}>
                  <OrganizationList />
                </Suspense>
              }
            />
            {/* <Route
              path="license"
              element={
                <Suspense fallback={<div>Идёт загрузка...</div>}>
                  <ClientLicSchemeList />
                </Suspense>
              }
            /> */}{" "}
            {/* ожидает бэкэнда */}
            <Route
              path="cart"
              element={
                <Suspense fallback={<div>Идёт загрузка корзины...</div>}>
                  <CartPage />
                </Suspense>
              }
            />
            <Route
              path="success_pay"
              element={
                <Suspense fallback={<div>Идёт загрузка...</div>}>
                  <SuccessPay />
                </Suspense>
              }
            />
            <Route
              path="download-invoice/:invoiceid"
              element={
                <Suspense fallback={<div>Идёт загрузка...</div>}>
                  <DownloadInvoiceBill />
                </Suspense>
              }
            />
            <Route
              path="invoice-result/confirmed"
              element={
                <Suspense fallback={<div>Идёт загрузка...</div>}>
                  <SuccessPay />
                </Suspense>
              }
            />
          </>
        )}
        <Route
          path="*"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <NotFound />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
