import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import config from "../../config";
import { Col, Row } from "react-bootstrap";
import classNames from "classnames";
import GoogleSVG from "../../assets/icons/google";
import usePlaceStore from "../../zustand/place-store";
import AuthService from "../../services/AuthService";
import useUserStore from "../../zustand/user-store";
import useCartStore from "../../zustand/cart-store";
import useLoginDialogStore from "../../zustand/login-modal-store";
import { ShopService } from "../../services/ShopService";
import { OAuth20Provider, ShopOAuth20Types } from "../../types/shop";
import showToast from "../../utils/toast";

enum Tabs {
  login,
  registration,
}

export default function LoginModal() {
  let location = useLocation();
  const { showLoginDialog, closeDialog } = useLoginDialogStore((state) => state);
  const { place, getAll: getPlaces, updatePlace } = usePlaceStore((state) => state);
  const { getUser } = useUserStore((state) => state);
  const { get: getCart } = useCartStore((state) => state);
  const [login, setLogin] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [namereg, setNamereg] = useState("");
  const [surnamereg, setSurnamereg] = useState("");
  const [middlenamereg, setMiddlenamereg] = useState("");
  const [emailreg, setEmailreg] = useState<string>("");
  const [passwordreg, setPasswordreg] = useState<string>("");
  const [phonereg, setPhonereg] = useState<string>("");
  const [oAuth20Method, setOAuth20Method] = useState<ShopOAuth20Types>();

  const [tab, setTab] = useState<Tabs>(Tabs.login);

  const onChangeTab = (tab: Tabs) => {
    setTab(tab);
  };

  useEffect(() => {
    ShopService.getAllOAuth20ByShop()
      .then((res) => {
        const kek = res.data.find((oa) => oa.oauth20type === OAuth20Provider.Google);
        setOAuth20Method(kek);
      })
      .catch((e) => showToast("error", e.response.data.message));
  }, []);

  const onLoginClick = () => {
    if (!place) return;
    AuthService.login(login.toLocaleLowerCase(), password, place.placeid)
      .then((res) => {
        localStorage.setItem(`${config.API_SHOP}-accessToken`, res.data.accessToken);
        localStorage.setItem(`${config.API_SHOP}-placeid`, res.data.placeid);
        updatePlace(res.data.placeid);
        getPlaces();
        getUser(res.data.userprofileid, res.data.email);
        getCart();
        closeDialog();
      })
      .catch((e) => {
        if (e.response) {
          alert(e.response.data.message);
        } else {
          alert(e.message);
        }
      });
  };
  const onRegistrationClick = () => {
    if (!place) return;
    AuthService.registration(emailreg.toLocaleLowerCase(), passwordreg, namereg, surnamereg, phonereg, place.placeid)
      .then((res) => {
        localStorage.setItem(`${config.API_SHOP}-accessToken`, res.data.accessToken);
        getUser(res.data.userprofileid, res.data.email);
        getPlaces();
        window.localStorage.setItem(`${config.API_SHOP}-placeid`, place.placeid);
        getCart();
        closeDialog();
      })
      .catch((e) => {
        if (e.response) {
          alert(e.response.data.message);
        } else {
          alert(e.message);
        }
      });
  };
  const onLoginGoogleClick = () => {
    if (!place || !oAuth20Method) return;
    window.open(
      `${config.API_URL}/oauth20?oauth20passportid=${oAuth20Method.oauth20passportid}&provider=${oAuth20Method.oauth20type}&shopid=${oAuth20Method.shopid}&originPath=${location.pathname}&placeid=${place.placeid}&target=0`,
      "_self"
    );
  };

  if (showLoginDialog) {
    return (
      <>
        <Modal
          show={true}
          onHide={closeDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ color: "#3e4652", zIndex: "100001" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Вход | Регистрация</Modal.Title>
          </Modal.Header>
          <Modal.Body className="tab-content" id="nav-tabContent">
            <Row>
              <Col xs={12} md={12} lg={12} xl={12}>
                <Row className="mt-3 px-4 align-items-center" role="group" aria-label="Базовая группа переключателей радио">
                  <Col
                    xxs={6}
                    xs={6}
                    md={6}
                    lg={6}
                    xl={6}
                    onClick={() => onChangeTab(Tabs.login)}
                    className={classNames(
                      "btn btn-buyer text-center rounded-0 ",
                      {
                        "border-top border-end border-start rounded-top": tab === Tabs.login,
                      },
                      {
                        "border-bottom rounded-0": tab === Tabs.registration,
                      }
                    )}
                  >
                    <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" defaultChecked />
                    <label htmlFor="btnradio1">Вход</label>
                  </Col>

                  <Col
                    xxs={6}
                    xs={6}
                    md={6}
                    lg={6}
                    xl={6}
                    onClick={() => onChangeTab(Tabs.registration)}
                    className={classNames(
                      "btn btn-buyer text-center  rounded-0",
                      {
                        "border-top border-start border-end rounded-top": tab === Tabs.registration,
                      },
                      {
                        "border-bottom rounded-0": tab === Tabs.login,
                      }
                    )}
                  >
                    <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" />
                    <label htmlFor="btn-check">Регистрация</label>
                  </Col>
                </Row>
                <div>
                  {tab === Tabs.login && (
                    <div className="modal-body p-5 ">
                      <div className="form-floating mb-3">
                        <input type="email" className="form-control rounded-4" value={login} onChange={(e) => setLogin(e.target.value)} />
                        <label>Email</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="password"
                          className="form-control rounded-4"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <label>Пароль</label>
                      </div>

                      <button className="w-100 mb-2 btn btn-lg rounded-4 btn-primary sign-up-btn" type="button" onClick={onLoginClick}>
                        Войти
                      </button>
                      <small className="text-muted">
                        Нажимая "Войти" вы соглашаетесь с{" "}
                        <Link to="/terms" style={{ color: "blue" }} onClick={closeDialog}>
                          политикой конфиденциальности
                        </Link>
                      </small>
                      <hr className="my-4" />
                      <h2 className="fs-5 fw-bold mb-3">Войти через сторонние сайты:</h2>

                      <div className="google-btn shadow text-center p-3 rounded-4" onClick={onLoginGoogleClick}>
                        <Row className="d-flex align-items-center">
                          <Col>
                            <GoogleSVG w={30} h={30} />
                          </Col>
                          <Col xs={9} sm={10} md={10} lg={10} xl={10} className="text-center">
                            <p className="m-0 btn-text">
                              <b>Войти через google</b>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  {tab === Tabs.registration && (
                    <div className="modal-body p-5 ">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control rounded-4"
                          value={surnamereg}
                          onChange={(e) => setSurnamereg(e.target.value)}
                        />
                        <label htmlFor="floatingInput">Фамилия</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control rounded-4"
                          value={namereg}
                          onChange={(e) => setNamereg(e.target.value)}
                        />
                        <label>Имя</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control rounded-4"
                          value={middlenamereg}
                          onChange={(e) => setMiddlenamereg(e.target.value)}
                        />
                        <label>Отчество</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="tel"
                          className="form-control rounded-4"
                          value={phonereg}
                          onChange={(e) => setPhonereg(e.target.value)}
                        />
                        <label>Номер телефона</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="email"
                          className="form-control rounded-4"
                          value={emailreg}
                          onChange={(e) => setEmailreg(e.target.value)}
                        />
                        <label>Email</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="password"
                          className="form-control rounded-4"
                          value={passwordreg}
                          onChange={(e) => setPasswordreg(e.target.value)}
                        />
                        <label>Пароль</label>
                      </div>

                      <button className="w-100 mb-2 btn btn-lg rounded-4 btn-primary sign-up-btn" onClick={onRegistrationClick}>
                        Зарегистрироваться
                      </button>
                      <small className="text-muted">
                        Нажимая "Зарегистрироваться" вы соглашаетесь с{" "}
                        <Link to="/terms" style={{ color: "blue" }} onClick={closeDialog}>
                          правилами
                        </Link>{" "}
                        пользования.
                      </small>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </>
    );
  } else return null;
}
