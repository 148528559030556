import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { InvoiceService } from "../../../services/InvoiceService";
import UserProfileService from "../../../services/UserProfileService";
import usePlaceStore from "../../../zustand/place-store";
import useUserStore from "../../../zustand/user-store";
import payKepperIcon from "../../../assets/img/logo4h.png";
import { createPaykeeperForm } from "../../../utils/createPaykeeperForm";
import { PaymentService } from "../../../services/PaymentService";
import useCartStore from "../../../zustand/cart-store";
import { InvoiceBuyerType, InvoicePaymentMethod, InvoicePaymentType } from "../../../types/invoice";
import showToast from "../../../utils/toast";
import useInvoiceStore from "../../../zustand/invoice-store";
import config from "../../../config";

export default function PeopleContent() {
  const { place } = usePlaceStore((state) => state);
  const { clear } = useCartStore((state) => state);
  const { user } = useUserStore((state) => state);
  const { invoiceState } = useInvoiceStore((state) => state);
  const [people, setPeople] = useState<any>({ surname: "", name: "", middlename: "", phone: "" });

  const makeInvoice = () => {
    if (!place || !invoiceState) return;
    InvoiceService.create(invoiceState.invoiceid, place.placeid).then((res) => {
      switch (res.data.payment.paymentmethod) {
        case InvoicePaymentMethod.PayKeeper:
          createPaykeeperForm(res.data.payment.paymentdata, res.data.payment.paymentlink);
          break;
        case InvoicePaymentMethod.Yookassa:
          window.location.href = res.data.payment.paymentlink;
          break;
      }
      localStorage.removeItem(`${config.API_SHOP}-invoiceid`);
      clear();
    });
  };

  useEffect(() => {
    UserProfileService.getUser()
      .then((res) => {
        setPeople(res.data);
      })
      .catch((e: any) => {
        if (e.response) {
          console.log(e.response.data);
        } else {
          console.log("Error", e.message);
        }
      });
  }, []);

  if (!invoiceState) return null;
  return (
    <>
      <Row className="justify-content-between">
        <Col className="me-3" xs={12}>
          <input
            type="text"
            className="w-100 rounded p-2 my-2 border"
            readOnly
            placeholder="ФИО"
            value={people.surname + " " + people.name + " " + people.middlename}
          />
          <input type="tel" className="w-100 rounded p-2 my-2 border" readOnly placeholder="Телефон" value={people.phone} />
          <input type="email" className="w-100 rounded p-2 my-2 border" readOnly placeholder="E-mail" value={user?.email} />
        </Col>
      </Row>

      <h2 className="mt-3">Выберите способ оплаты</h2>
      <div className="border border-secondary rounded my-3">
        <Row className="align-items-center btn btn-payment d-flex text-start" htmlFor="flexRadioDefault1">
          <Col xs={1}>
            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" readOnly checked={true} />
          </Col>
          {/* <Col xs={1}>
            <span>PayKeeper</span>
          </Col> */}
          <Col xs={3}>
            <label className="row  p-0  fs-5 text-start w-100 form-check-label form-check-label " htmlFor="flexRadioDefault1">
              <img className="" src={payKepperIcon} alt="ЮMoney-icon"></img>
            </label>
          </Col>
        </Row>
      </div>
      <p className="my-3">
        С правилами оплаты, возврата товара и отказа от услуг можно ознакомиться{" "}
        <Link to="/payment-terms">
          <u>здесь</u>
        </Link>
        .
      </p>
      <p className="my-3">
        Оплачивая заказ, Вы соглашаетесь с условиями{" "}
        <Link to="/privacy">
          <u>лицензионного соглашения</u>
        </Link>
        .
      </p>
      <Row className="align-items-center">
        <Col className=" me-4" xs={12} md={12} lg={3} xl={3}>
          <Button className="style-btn-action py-3 w-100 fs-4" onClick={makeInvoice}>
            Оплатить заказ
          </Button>
        </Col>
        <Col xs={12} md={12} lg={8} xl={8}>
          <p className="mb-0">Чек будет отправлен на эл. почту {user?.email}</p>
        </Col>
      </Row>
    </>
  );
}
