import { Button, Card, Col, Container, Row } from "react-bootstrap";
import React from "react";

const SuccessPay: React.FC = () => {
  return (
    <Container className="">
      <Card className="mx-auto my-5 text-dark mw-50 p-4 w-50">
        <Row className="justify-content-between align-items-center text-wrap">
          <Col xs={9} md={10} lg={10} xl={10}>
            <h3 className="m-0">Заказ подвержден</h3>
          </Col>
          <Col xs={3} md={2} lg={2} xl={2}>
            <img className="w-50 align-items-end" src="/images/сheck-blue.png" alt="" />
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default SuccessPay;
