import $api from "../axios/config.axios";
import { AxiosResponse } from "axios";
import { SHA256 } from "../utils/SHA256";
import config from "../config";

export default class UserProfileService {
  static getUser(): Promise<AxiosResponse<any>> {
    return $api.get<any>("/userprofile");
  }

  static updateFIO({ surname, name, middlename }: any): Promise<AxiosResponse<any>> {
    return $api.put<any>("/userprofile", { surname, name, middlename });
  }
  static updatePhone(phone: string): Promise<AxiosResponse<any>> {
    return $api.put<any>("/userprofile/phone", { phone });
  }

  static updateEmail({ email }: any): Promise<AxiosResponse<any>> {
    return $api.put<any>("/userprofile/email", { email });
  }

  static updatePassByOldPass(oldPassword: string, newPassword: string): Promise<AxiosResponse<any>> {
    const saltOldPass = SHA256(oldPassword + config.SALT);
    const saltNewPass = SHA256(newPassword + config.SALT);
    return $api.put<any>("/userprofile/pass/oldpass", { oldPassword: saltOldPass, newPassword: saltNewPass });
  }

  static delete(): Promise<AxiosResponse<any>> {
    return $api.delete<any>("/userprofile");
  }
}
