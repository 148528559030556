import { toast } from "react-toastify";

function showToast(type: "info" | "success" | "warning" | "error", message: string, autoclose?: number) {
  toast(message, {
    position: "bottom-right",
    hideProgressBar: true,
    autoClose: autoclose || 2000,
    type: type,
    theme: "dark",
    className: "toast-message",
  });
}
export default showToast;
