import axios from "axios";
import config from "../config";
import Cookies from "js-cookie";
import AuthService from "../services/AuthService";
import showToast from "../utils/toast";

const $api = axios.create({
  withCredentials: true,
  baseURL: config.API_URL,
  headers: { shop: config.API_SHOP },
});

$api.interceptors.request.use(
  (config: any) => {
    if (typeof window !== "undefined") {
      const accessToken = localStorage.getItem(`${config.headers.shop}-accessToken`);

      if (accessToken) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
        };
      }

      const shopid_cookie = Cookies.get(`${config.headers.shop}-shopid`);
      const shopid_ls = localStorage.getItem(`${config.headers.shop}-shopid`);

      if (shopid_cookie || shopid_ls) {
        config.headers = {
          ...config.headers,
          shopid: shopid_cookie || shopid_ls,
        };
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

$api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (typeof window !== "undefined") {
      const originalRequest = error.config;

      if (error.response.status === 401 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        try {
          const response = await AuthService.refresh();
          localStorage.setItem(`${config.API_SHOP}-accessToken`, response.data.accessToken);
          return $api.request(originalRequest);
        } catch (e) {
          localStorage.removeItem(`${config.API_SHOP}-accessToken`);
          // window.open(`/`, "_self");
        }
      }
      throw error;
    }
  }
);

export default $api;
