// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer_footer__UwIOH {\n  position: fixed;\n  background: #ececec;\n  width: 100%;\n  padding: 6px 0;\n  bottom: 0;\n}\n.Footer_footer__UwIOH .Footer_text__zKQ8K {\n  color: #3e4652;\n}\n.Footer_footer__UwIOH .Footer_text__zKQ8K .Footer_link__MxpVT {\n  color: #00b4b6 !important;\n}", "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,mBAAA;EACA,WAAA;EACA,cAAA;EACA,SAAA;AACF;AACE;EACE,cAAA;AACJ;AAAI;EACE,yBAAA;AAEN","sourcesContent":[".footer {\r\n  position: fixed;\r\n  background: #ececec;\r\n  width: 100%;\r\n  padding: 6px 0;\r\n  bottom: 0;\r\n  // text-align: center;\r\n  .text {\r\n    color: #3e4652;\r\n    .link {\r\n      color: #00b4b6 !important;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "Footer_footer__UwIOH",
	"text": "Footer_text__zKQ8K",
	"link": "Footer_link__MxpVT"
};
export default ___CSS_LOADER_EXPORT___;
