const config = {
  // API_URL: "http://localhost:62222/api",
  API_URL: "http://localhost:8000/api",
  // API_URL: "http://neg24.ru/api",
  API_WEBSOCKET: "http://localhost:8000",
  // API_WEBSOCKET: "http://neg24.ru",
  API_SHOP: "neg24",

  SALT: "no{sdfgdfsd][U8kdfkO)*hj4,dxd",

  DEFAULT_SHOP_CITY: "Красноярск",
};

export default config;
