export type ShopPickPointTypes = {
  pickuppointid: string;
  name: string;
  warehouseid: string;
  warehousename: string;
  placeid: string;
  placename: string;
};

export type ShopOAuth20Types = {
  oauth20passportid: string;
  shopid: string;
  shopname: string;
  clientid: string;
  secret: string;
  oauth20type: OAuth20Provider;
};

export enum OAuth20Provider {
  Yandex,
  Google,
}
export const OAuth20ProviderConst = {
  yandex: "yandex",
  google: "google",
};
