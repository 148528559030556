import { create } from "zustand";
import CartService from "../services/CartService";
import showToast from "../utils/toast";
import config from "../config";

export type CartItemState = {
  cartid: string;
  cnt: number;
  temporarysum: number;
  price: number;
  isexists: boolean;

  nomenclatureid: string;
  nomenclaturename: string;
  nomenclaturenamefull: string;
  nomenclaturecode: string;
  nomenclatureimageid: string;

  packagessetitemid: string;
  packagessetitemname: string;
};

export type CartState = {
  cart: CartItemState[];
};

interface CartAction {
  get: () => void;
  insert: (nomenclatureId: string) => Promise<void>;
  remove: (cartId: string) => Promise<void>;
  increment: (cartId: string) => Promise<void>;
  decrement: (cartId: string) => Promise<void>;
  setCount: (cartId: string, count: number) => Promise<void>;
  clear: () => Promise<void>;
  clearState: () => void;
  check: () => Promise<boolean>;
}

const cartInit: CartItemState[] = [];

const useCartStore = create<CartState & CartAction>()((set, get) => ({
  cart: cartInit,

  get: async () => {
    await CartService.getAll()
      .then((res) => set({ cart: res.data }))
      .catch((e) => showToast("error", e.response.data.message || "Не удалось получить корзину"));
  },

  insert: async (nomenclatureId: string) => {
    await CartService.insert(nomenclatureId)
      .then((res) => {
        localStorage.removeItem(`${config.API_SHOP}-invoiceid`);
        set({ cart: [...get().cart, res.data] });
      })
      .catch((e) => showToast("error", e.response.data.message || "Не удалось добавить в корзину"));
  },

  remove: async (cartId: string) => {
    await CartService.delete(cartId)
      .then(() => {
        localStorage.removeItem(`${config.API_SHOP}-invoiceid`);
        set({ cart: get().cart.filter((item) => item.cartid !== cartId) });
      })
      .catch((e) => showToast("error", e.response.data.message || "Не удалось удалить"));
  },

  increment: async (cartId: string) => {
    await CartService.increment(cartId)
      .then(() => {
        localStorage.removeItem(`${config.API_SHOP}-invoiceid`);
        set({
          cart: get().cart.map((i) => (i.cartid === cartId ? { ...i, cnt: i.cnt + 1, temporarysum: i.temporarysum + i.price } : i)),
        });
      })
      .catch((e) => showToast("error", e.response.data.message || "Не удалось добавить"));
  },

  decrement: async (cartId: string) => {
    await CartService.decrement(cartId)
      .then(() => {
        localStorage.removeItem(`${config.API_SHOP}-invoiceid`);
        set({
          cart: get().cart.map((i) => (i.cartid === cartId ? { ...i, cnt: i.cnt - 1, temporarysum: i.temporarysum - i.price } : i)),
        });
      })
      .catch((e) => showToast("error", e.response.data.message || "Не удалось убрать"));
  },

  setCount: async (cartId: string, count: number) => {
    await CartService.setCount(cartId, count)
      .then(() => {
        localStorage.removeItem(`${config.API_SHOP}-invoiceid`);
        set({
          cart: get().cart.map((i) => (i.cartid === cartId ? { ...i, cnt: count, temporarysum: i.price * count } : i)),
        });
      })
      .catch((e) => showToast("error", e.response.data.message || "Не удалось установить количество"));
  },

  clear: async () => {
    await CartService.clear()
      .then(() => {
        localStorage.removeItem(`${config.API_SHOP}-invoiceid`);
        set({ cart: [] });
      })
      .catch((e) => showToast("error", e.response.data.message || "Не удалось очистить"));
  },

  clearState: () => {
    localStorage.removeItem(`${config.API_SHOP}-invoiceid`);
    set({ cart: [] });
  },

  check: async () => {
    const res = await CartService.check().then((res) => {
      return res.data;
    });
    return res;
  },
}));
export default useCartStore;
