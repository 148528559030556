import { AxiosResponse } from "axios";
import $api from "../axios/config.axios";

interface IPlace {
  placeid: string;
  name: string;
  namesys: string;
  comstructureid: string;
  currencyid: string;
  shopid: string;
  isdefault: boolean;
}

export class ShopPlaceService {
  // Получение всех городов магазина
  static async getAll(): Promise<AxiosResponse<IPlace[]>> {
    return await $api.get<IPlace[]>(`shop/place`);
  }

  // Получение города магазина
  static async getOne(placeid: string): Promise<AxiosResponse<IPlace>> {
    return await $api.get<IPlace>(`shop/place/placeid`, { params: { placeid } });
  }

  // Получение города магазина по названию
  static async getOneByName(placename: string): Promise<AxiosResponse<IPlace>> {
    return await $api.get<IPlace>(`shop/place/placename`, { params: { placename } });
  }

  // Получение города магазина по названию
  static async getOneDefault(): Promise<AxiosResponse<IPlace>> {
    return await $api.get<IPlace>(`shop/place/default`);
  }
}
