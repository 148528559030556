import { io, Socket } from "socket.io-client";

import config from "../config";

let socket: Socket;

export default function createSocket() {
  if (!socket) {
    socket = io(config.API_WEBSOCKET, {
      // Здесь вы можете настроить дополнительные параметры соединения
      path: "/api/websocket",
      transports: ["websocket", "polling"],
      // другие опции
    });
  }
  return socket;
}
