export type InvoiceTypes = {
  invoiceid: string;
  invoicenumber: number;

  buyerid: string;
  buyertype: InvoiceBuyerType;
  buyerorganizationid: string;
  organizationid: string;
  organizationname: string;

  currencyid: string;
  currencyname: string;
  currencynamesys: string;
  currencynameshort: string;

  purposepartnerid: string;
  purposepartnername: string;

  comment: string;

  paymentid: string;

  invoicepaymentstatusid: string;
  invoicepaymentstatusname: string;
  invoicepaymentstatustype: InvoicePaymentStatusType;

  cnt: number;
  price: number;

  paymenttype: InvoicePaymentType;
  paymentmethod: InvoicePaymentMethod;

  assemblystatusid: string;
  assemblystatusname: string;
  assemblystatustype: InvoiceAssemblyStatusType;

  recorddatecreate: Date;
};
export type InvoiceExTypes = {
  invoiceid: string;
  invoicenumber: number;

  buyerid: string;
  buyertype: InvoiceBuyerType;
  buyerorganizationid: string;
  organizationid: string;
  organizationname: string;

  currencyid: string;
  currencyname: string;
  currencynamesys: string;
  currencynameshort: string;

  purposepartnerid: string;
  purposepartnername: string;

  comment: string;

  paymentid: string;

  invoicepaymentstatusid: string;
  invoicepaymentstatusname: string;
  invoicepaymentstatustype: InvoicePaymentStatusType;

  cnt: number;
  price: number;

  paymenttype: InvoicePaymentType;
  paymentmethod: InvoicePaymentMethod;

  assemblystatusid: string;
  assemblystatusname: string;
  assemblystatustype: InvoiceAssemblyStatusType;

  recorddatecreate: Date;
};

export type GoodsInvoiceTypes = {
  goodsinvoiceid: string;

  deliverytype: InvoicePickType;
  deliverymethod: InvoicePickDeliveryMethod;
  deliverystatusid: string;

  peopledeliveryaddressid: string | null;
  organizationdeliveryaddressid: string | null;
};

export type InvoiceHistoryTypes = {
  invoiceId: string;
  invoiceNumber: number;
  invoiceAmount: number;
  invoiceStatus: number;
  invoiceCreationDate: Date;
  buyer: {
    buyerType: InvoiceBuyerType;
    buyerName: string;
  };
  payment: {
    paymentId: string;
    paymentType: number;
    paymentMethod: number;
    paymentStatusId: string;
    paymentStatusType: number;
    paymentStatusName: string;
  };
  assembly: {
    assemblyStatusId: string;
    assemblyStatusType: number;
    assemblyStatusName: string;
  };
  delivery: {
    deliveryId: string;
    deliveryType: InvoicePickType;
    deliveryMethod: number;
    deliveryStatusId: string;
    deliveryStatusType: number;
    deliveryStatusName: string;
    deliveryAmount: number | null;
    address: {
      countryid: string | null;
      countryname: string | null;
      regionid: string | null;
      regionname: string | null;
      districtid: string | null;
      districtname: string | null;
      cityid: string | null;
      cityname: string | null;
      streetid: string | null;
      streetname: string | null;
      buildingid: string | null;
      buildingname: string | null;
      symbol: string | null;
      apartment: string | null;
    };
  };
};

export type InvoiceItemTypes = {
  invoiceitemid: string;
  invoiceid: string;
  cnt: number;
  price: number;
  measurename: string;
  packagessetitemid: string;
  packagessetitemname: string;
  nomenclatureid: string;
  nomenclaturename: string;
  nomenclaturenamefull: string;
  images: { nomenclatureimageid: string; nomenclatureid: string; name: string; imageid: string }[];
};

export type InvoiceCreate = {
  buyer: InvoiceBuyer;
  delivery: InvoiceDelivery;
  payment: InvoicePayment;
  tariff_code?: number; // TODO: Завести перечисление тарифов от сдек
};
export type InvoiceBuyer = {
  buyertype: InvoiceBuyerType;
  organizationid: string | null;
};
export type InvoiceDelivery = {
  deliverytype: InvoicePickType;
  deliverymethod: InvoicePickDeliveryMethod;
  selfpickuppointid: string | null;
  pickuppointid: string | null;
};
export type InvoicePayment = {
  paymenttype: InvoicePaymentType;
  paymentmethod: InvoicePaymentMethod;
};

export type PickUpPointTypes = {
  pickuppointid: string;
  name: string;
  warehouseid: string;
  placeid: string;
  placename: string;
};

export type TimeWork = {
  isShowHeader: boolean;
  header: string;
  isShowFooter: boolean;
  footer: string;
  timeZoneName: string;
  pauseCaption: string;
  holidayCaption: string;
  twentyfourHourCaption: string;
  isGroupTime: boolean;
  isTimeRangeShow: boolean;
  name: string;
  daysTimeWork: TimeWorkDay[];
};
export type TimeWorkDay = {
  index: string;
  isWorkDay: boolean;
  isPausePresent: boolean;
  color: string;
  caption: string;
  workFrom: string;
  workTo: string;
  pauseFrom: string;
  pauseTo: string;
};

export type DeliveryCDEK = {
  deliverycdekid: string;
  invoicecdekid: string;
  pickuppointcdekid: string;
  pricecalc: number;
  currencyid: string;
  calendarmin: number;
  calendarmax: string;
  weightcalc: number;
  invoiceid: string;
  desireddeliverydate: Date;
};

export enum InvoiceBuyerType {
  Personal,
  Corporate,
}
export enum InvoicePickType {
  SelfPickUp,
  DoorToDoor,
  PickUpPoint,
}
export enum InvoicePickDeliveryMethod {
  CDEK,
}
export enum InvoicePaymentType {
  PostPayment,
  PrePayment,
}
export enum InvoicePaymentMethod {
  Yookassa,
  Bill,
  PayKeeper,
  Cash,
  Card,
}
export enum InvoicePaymentStatusType {
  Initial,
  PaymentExpected,
  Paid,
  Canceled,
}
export enum InvoiceAssemblyStatusType {
  Initial,
  InProgress,
  Complited,
  Rejected,
}
export enum InvoiceDeliveryStatusType {
  Initial,
  InProgress,
  Delivered,
  Cancelled,
}
