// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WaterWaveRing_loader__rpIIA {\n  position: relative;\n  width: 150px;\n  height: 150px;\n}\n.WaterWaveRing_loader__rpIIA div {\n  position: absolute;\n  border: 5px solid #00b4b6;\n  border-radius: 50%;\n  animation: WaterWaveRing_loader__rpIIA 1s ease-out infinite;\n}\n.WaterWaveRing_loader__rpIIA div:nth-child(2) {\n  animation-delay: -0.5s;\n}\n\n@keyframes WaterWaveRing_loader__rpIIA {\n  0% {\n    top: 75px;\n    left: 75px;\n    width: 0;\n    height: 0;\n    opacity: 1;\n  }\n  100% {\n    top: 0px;\n    left: 0px;\n    width: 150px;\n    height: 150px;\n    opacity: 0;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/Loaders/WaterWaveRing/WaterWaveRing.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,aAAA;AACF;AAAE;EACE,kBAAA;EACA,yBAAA;EACA,kBAAA;EACA,2DAAA;AAEJ;AADI;EACE,sBAAA;AAGN;;AAGA;EACE;IACE,SAAA;IACA,UAAA;IACA,QAAA;IACA,SAAA;IACA,UAAA;EAAF;EAEA;IACE,QAAA;IACA,SAAA;IACA,YAAA;IACA,aAAA;IACA,UAAA;EAAF;AACF","sourcesContent":[".loader {\r\n  position: relative;\r\n  width: 150px;\r\n  height: 150px;\r\n  div {\r\n    position: absolute;\r\n    border: 5px solid #00b4b6;\r\n    border-radius: 50%;\r\n    animation: loader 1s ease-out infinite;\r\n    &:nth-child(2) {\r\n      animation-delay: -0.5s;\r\n    }\r\n  }\r\n  \r\n}\r\n\r\n@keyframes loader {\r\n  0% {\r\n    top: 75px;\r\n    left: 75px;\r\n    width: 0;\r\n    height: 0;\r\n    opacity: 1;\r\n  }\r\n  100% {\r\n    top: 0px;\r\n    left: 0px;\r\n    width: 150px;\r\n    height: 150px;\r\n    opacity: 0;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "WaterWaveRing_loader__rpIIA"
};
export default ___CSS_LOADER_EXPORT___;
