import { create } from "zustand";

interface LoginDialogState {
  showLoginDialog: boolean;
}

interface LoginDialogAction {
  openDialog: () => void;
  closeDialog: () => void;
}

const useLoginDialogStore = create<LoginDialogState & LoginDialogAction>()((set) => ({
  showLoginDialog: false,
  openDialog: () => {
    set({ showLoginDialog: true });
  },
  closeDialog: () => set({ showLoginDialog: false }),
}));

export default useLoginDialogStore;
