// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotFoundBlock_root__Lb6p1 {\n  padding: 100px;\n  max-width: 750px;\n  margin: 0 auto;\n  text-align: center;\n}\n.NotFoundBlock_root__Lb6p1 span {\n  font-size: 64px;\n}\n\n.NotFoundBlock_description__ztxye {\n  font-size: 22px;\n}", "",{"version":3,"sources":["webpack://./src/components/NotFoundBlock/NotFoundBlock.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;AACF;AACE;EACE,eAAA;AACJ;;AAGA;EACE,eAAA;AAAF","sourcesContent":[".root {\r\n  padding: 100px;\r\n  max-width: 750px;\r\n  margin: 0 auto;\r\n  text-align: center;\r\n\r\n  span {\r\n    font-size: 64px;\r\n  }\r\n}\r\n\r\n.description {\r\n  font-size: 22px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "NotFoundBlock_root__Lb6p1",
	"description": "NotFoundBlock_description__ztxye"
};
export default ___CSS_LOADER_EXPORT___;
