import React from "react";
import config from "../../config";
import { Image } from "../../services/ImageService";
import nomenclatureNoImg from "../../assets/img/nomenclature_no_image.png";
import { Link } from "react-router-dom";
import usePlaceStore from "../../zustand/place-store";
import useUserStore from "../../zustand/user-store";
import useCurrencyStore from "../../zustand/currency-store";
import useCartStore from "../../zustand/cart-store";
import useLoginDialogStore from "../../zustand/login-modal-store";

export type ClientShopItemProps = {
  showcaseid: string;
  productid: string;
  productname: string;
  nomenclatureid: string;
  nomenclaturename: string;
  price: number;
  images: Image[];
};

export default function ClientShopItem({ showcaseid, nomenclatureid, nomenclaturename, productname, price, images }: ClientShopItemProps) {
  const { openDialog } = useLoginDialogStore((state) => state);
  const { currency } = useCurrencyStore((state) => state);
  const { place } = usePlaceStore((state) => state);
  const { user } = useUserStore((state) => state);
  const { cart, insert } = useCartStore((state) => state);

  const onClickAdd = () => {
    if (!place) return;
    if (!user.userprofileid) return openDialog();
    insert(nomenclatureid);
  };

  const cartItemExist = (): boolean => {
    const item = cart.find((item) => item.nomenclatureid === nomenclatureid);
    if (item) return true;
    else return false;
  };

  return (
    <div className="shop-card card h-100 shadow py-4  mx-auto">
      <Link className="my-auto" to={`/nomenclature/${nomenclatureid}`}>
        <img
          alt="noimg"
          className="w-50"
          src={images.length > 0 ? `${config.API_URL}/nomenclature/image/` + images[0].imageid : nomenclatureNoImg}
        />
      </Link>
      <div className="card-body d-flex flex-column">
        <Link to={`/nomenclature/${nomenclatureid}`}>
          <h4 className="card-title">{nomenclaturename}</h4>
          <hr className="w-25 mx-auto" />
        </Link>
        <div className="mt-auto">
          <h4>
            <b>{price === null ? "не указана" : price.toLocaleString()}</b> {currency.nameshort}
            /год
          </h4>
          {cartItemExist() && price && (
            <button type="button" className="btn buy-btn-in-cart mt-3" disabled>
              В корзине
            </button>
          )}
          {!cartItemExist() && price && (
            <button type="button" className="btn style-btn-link mt-3" onClick={onClickAdd}>
              В корзину
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
