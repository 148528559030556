export type PaykeeperFormData = {
  sum: string;
  clientid: string;
  orderid: string;
  service_name: string;
  client_email: string;
  client_phone: string;
  user_result_callback: string;
};

export function createPaykeeperForm(paykeeperdata: any, paykeeperlink: string) {
  const form = document.createElement("form");
  form.method = "POST";
  form.action = paykeeperlink;
  for (const key in paykeeperdata) {
    if (paykeeperdata.hasOwnProperty(key)) {
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = key;
      hiddenField.value = paykeeperdata[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  console.log(form);

  form.submit();
}
