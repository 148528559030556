import React from "react";
import { Container } from "react-bootstrap";
import s from "./Terms.module.scss";

export default function Terms() {
  return (
    <Container className="text-full-width text-b my-5 fs-6">
      <h1>Политика компании в отношении обработки персональных данных</h1>
      <p>ред. от 29 ноября 2022</p>
      <p>
        Настоящая Политика компании в отношении обработки персональных данных (далее — Политика конфиденциальности) действует в отношении
        всей информации, которую Сайт компании ООО «Негоциант», расположенный на доменном имени www.neg24.ru , может получить о Пользователе
        во время использования Сайта компании ООО «Негоциант», программ и продуктов Сайта компании ООО «Негоциант».
      </p>
      <h3>1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</h3>
      <p>1.1. В настоящей Политике конфиденциальности используются следующие термины:</p>
      <p>
        1.1.1. «Администрация Сайта Компании (далее — Администрация сайта) — уполномоченные сотрудники на управление Сайтом, действующие от
        имени ООО «Негоциант», которые организуют и (или) осуществляют обработку персональных данных, а также определяют цели обработки
        персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
      </p>
      <p>
        1.1.2. «Персональные данные» — любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу
        (субъекту персональных данных).
      </p>
      <p>
        1.1.3. «Оператор персональных данных» (далее — Оператор) — государственный орган, муниципальный орган, юридическое или физическое
        лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также
        определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые
        с персональными данными.
      </p>
      <p>
        1.1.4. «Субъект персональных данных» — физическое лицо, которое прямо или косвенно определено или определяемо с помощью персональных
        данных.
      </p>
      <p>
        1.1.5. «Обработка персональных данных» — любое действие (операция) или совокупность действий (операций), совершаемых с персональными
        данными с использованием средств автоматизации или без использования таких средств, включая сбор, запись, систематизацию,
        накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление,
        доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
        <h6 className="my-3">Понятие «Обработки персональных данных включает в себя:</h6>
        <ul className={s.list}>
          <li className="mb-3">
            автоматизированная обработка персональных данных — обработка персональных данных с помощью средств вычислительной техники;
          </li>
          <li className="mb-3">
            распространение персональных данных — действия, направленные на раскрытие персональных данных неопределенному кругу лиц;
          </li>
          <li className="mb-3">
            предоставление персональных данных — действия, направленные на раскрытие персональных данных определенному лицу или
            определенному кругу лиц;
          </li>
          <li className="mb-3">
            блокирование персональных данных — временное прекращение обработки персональных данных (за исключением случаев, если обработка
            необходима для уточнения персональных данных);
          </li>
          <li className="mb-3">
            уничтожение персональных данных — действия, в результате которых становится невозможным восстановить содержание персональных
            данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных
            данных;
          </li>
          <li className="mb-3">
            обезличивание персональных данных — действия, в результате которых становится невозможным без использования дополнительной
            информации определить принадлежность персональных данных конкретному субъекту персональных данных;
          </li>
          <li className="mb-3">
            информационная система персональных данных — совокупность содержащихся в базах данных персональных данных и обеспечивающих их
            обработку информационных технологий и технических средств;
          </li>
          <li>
            трансграничная передача персональных данных — передача персональных данных на территорию иностранного государства органу власти
            иностранного государства, иностранному физическому лицу или иностранному юридическому лицу.
          </li>
        </ul>
      </p>
      <p>
        1.1.6. «Конфиденциальность персональных данных» — обязательное для соблюдения Оператором или иным получившим доступ к персональным
        данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного
        основания.
      </p>
      <p>
        1.1.7. «Пользователь Сайта компании ООО «Негоциант» (далее Пользователь) — лицо, имеющее доступ к Сайту, посредством сети Интернет и
        использующее Сайт компании ООО «Негоциант».
      </p>
      <p>
        1.1.8. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент
        или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.
      </p>
      <p>1.1.9. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.</p>
      <h3>2. ОБЩИЕ ПОЛОЖЕНИЯ</h3>
      <p>
        2.1. Использование Пользователем Сайта компании ООО «Негоциант» означает согласие с настоящей Политикой конфиденциальности и
        условиями обработки персональных данных Пользователя.
      </p>
      <p>
        2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование Сайта компании ООО
        «Негоциант».
      </p>
      <p>
        2.3. Настоящая Политика конфиденциальности применяется только к Сайту компании ООО «Негоциант». Сайт компании ООО «Негоциант» не
        контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте
        компании ООО «Негоциант».
      </p>
      <p>
        2.4. Администрация Сайта не проверяет достоверность персональных данных, предоставляемых Пользователем Сайта компании ООО
        «Негоциант».
      </p>
      <h3>3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</h3>
      <p>
        3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации Сайта компании ООО «Негоциант» по неразглашению
        и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации
        Сайта при регистрации на Сайте компании ООО «Негоциант» или при оформлении заказа для приобретения Товара.
      </p>
      <p>
        3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем в
        момент регистрации на Сайте компании ООО «Негоциант» и включают в себя следующую информацию:
      </p>
      <p>3.2.1. фамилию, имя, отчество Пользователя;</p>
      <p>3.2.2. контактный телефон Пользователя;</p>
      <p>3.2.3. адрес электронной почты (e-mail);</p>
      <p>
        3.2.4. пользовательские данные (сведения о местоположении; тип и версия операционной системы (ОС), тип и версия Браузера, тип
        устройства и разрешение его экрана, информация о поисковых запросах Пользователя, источнике откуда пришел на сайт пользователь; с
        какого сайта или по какой рекламе; язык ОС и Браузера; история перехода по адресным ссылкам; ip-адрес);{" "}
      </p>
      <p>3.2.5. сведения о расчетных счетах и реквизитах платежных карт Пользователя.</p>
      <p>
        3.3. Сайт компании ООО «Негоциант» защищает Данные, которые автоматически передаются в процессе просмотра рекламных блоков и при
        посещении страниц, на которых установлен статистический скрипт системы («пиксель»):
      </p>
      <ul className={s.list}>
        <li className="mb-3">IP адрес;</li>
        <li className="mb-3">информация из cookies;</li>
        <li className="mb-3">информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);</li>
        <li className="mb-3">время доступа;</li>
        <li className="mb-3">адрес страницы, на которой расположен рекламный блок;</li>
        <li className="mb-3">реферер (адрес предыдущей страницы).</li>
      </ul>
      <p>3.3.1. Отключение cookies может повлечь невозможность доступа к частям сайта компании ООО «Негоциант», требующим авторизации.</p>
      <p>
        3.3.2. Сайт компании ООО «Негоциант» осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация используется с
        целью выявления и решения технических проблем, для контроля законности проводимых финансовых платежей.
      </p>
      <p>
        3.4. Любая иная персональная информация, не оговоренная выше (история покупок, используемые браузеры и операционные системы,
        аудио-визуальные материалы, оставленные в процессе контакта с представителем организации, и т.д.), подлежит надежному хранению и
        нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.
      </p>
      <h3>4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</h3>
      <p>4.1. Персональные данные Пользователя Администрация Сайта компании ООО «Негоциант» может использовать в целях:</p>
      <p>
        4.1.1. Идентификации Пользователя, зарегистрированного на Сайте компании ООО «Негоциант», для оформления заказа и (или) заключения
        Договора купли-продажи товара дистанционным способом с Сайта компании ООО «Негоциант».
      </p>
      <p>4.1.2. Предоставления Пользователю доступа к персонализированным ресурсам Сайта компании ООО «Негоциант».</p>
      <p>
        4.1.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта
        компании ООО «Негоциант», оказания услуг, обработка запросов и заявок от Пользователя.
      </p>
      <p>4.1.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.</p>
      <p>4.1.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.</p>
      <p>4.1.6. Создания учетной записи для совершения покупок, если Пользователь дал согласие на создание учетной записи.</p>
      <p>4.1.7. Уведомления Пользователя Сайта компании ООО «Негоциант» о состоянии Заказа.</p>
      <p>
        4.1.8. Обработки и получения платежей, подтверждения налога или налоговых льгот, оспаривания платежа, определения права на получение
        кредитной линии Пользователем.
      </p>
      <p>
        4.1.9. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с
        использованием Сайта компании ООО «Негоциант».
      </p>
      <p>
        4.1.10. Предоставления Пользователю с его согласия, обновлений продукции, специальных предложений, информации о ценах, новостной
        рассылки и иных сведений от имени Сайта компании ООО «Негоциант» или от имени партнеров компании ООО «Негоциант».
      </p>
      <p>4.1.11. Осуществления рекламной деятельности с согласия Пользователя.</p>
      <p>
        4.1.12. Предоставления доступа Пользователю на сайты или сервисы партнеров компании ООО «Негоциант» с целью получения продуктов,
        обновлений и услуг.
      </p>
      <h3>5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</h3>
      <p>
        5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в
        информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.
      </p>
      <p>
        5.2. Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные данные третьим лицам, в частности,
        курьерским службам, организациями почтовой связи, операторам электросвязи, исключительно в целях выполнения заказа Пользователя,
        оформленного на Сайте компании ООО «Негоциант», включая доставку Товара.
      </p>
      <p>
        5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации только
        по основаниям и в порядке, установленным законодательством Российской Федерации.
      </p>
      <p>
        5.4. При утрате или разглашении персональных данных Администрация сайта информирует Пользователя об утрате или разглашении
        персональных данных.
      </p>
      <p>
        5.5. Администрация сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от
        неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных
        неправомерных действий третьих лиц.
      </p>
      <p>
        5.6. Администрация сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных
        последствий, вызванных утратой или разглашением персональных данных Пользователя.
      </p>
      <h3>6. ОБЯЗАТЕЛЬСТВА СТОРОН</h3>
      <p>6.1. Пользователь обязан:</p>
      <p>6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом компании ООО «Негоциант».</p>
      <p>6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.</p>
      <p>6.2. Администрация сайта обязана:</p>
      <p>6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.</p>
      <p>
        6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения
        Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных
        персональных данных Пользователя, за исключением п.п. 5.2. и 5.3. настоящей Политики Конфиденциальности.
      </p>
      <p>
        6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно
        используемого для защиты такого рода информации в существующем деловом обороте.
      </p>
      <p>
        6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса
        Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период
        проверки, в случае выявления недостоверных персональных данных или неправомерных действий.
      </p>
      <h3>7. ОТВЕТСТВЕННОСТЬ СТОРОН</h3>
      <p>
        7.1. Администрация сайта, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с
        неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации, за исключением случаев,
        предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики Конфиденциальности.
      </p>
      <p>
        7.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт ответственность, если данная
        конфиденциальная информация:
      </p>
      <p>7.2.1. Стала публичным достоянием до её утраты или разглашения.</p>
      <p>7.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.</p>
      <p>7.2.3. Была разглашена с согласия Пользователя.</p>
      <h3>8. РАЗРЕШЕНИЕ СПОРОВ</h3>
      <p>
        8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем Сайта компании ООО «Негоциант» и
        Администрацией сайта, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).
      </p>
      <p>
        8.2. Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о
        результатах рассмотрения претензии.
      </p>
      <p>
        8.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством
        Российской Федерации.
      </p>
      <p>
        8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта применяется действующее
        законодательство Российской Федерации.
      </p>
      <h3>9. СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
      <p>
        Пользователь, оставляя заявку (делая заказ) на интернет-сайте neg24.ru, предоставляет настоящее Согласие на обработку персональных
        данных (далее – Согласие). Действуя свободно, своей волей и в своем интересе, а также подтверждая свою дееспособность, Пользователь
        дает свое согласие ООО «Негоциант» (ОГРН: 1192468041941 ИНН: 2465328990), юридический адрес: 660118, г.Красноярск, Северное шоссе,
        д.7 корп.в/2, пом.13 (далее по тексту — Оператор) на обработку своих персональных данных со следующими условиями:
      </p>
      <p>
        9.1. Настоящее Согласие дается Пользователем на обработку персональных данных, как без использования средств автоматизации, так и с
        их использованием.
      </p>
      <p>
        9.2. Согласие дается на обработку следующих персональных данных Пользователя: персональные данные, не являющиеся специальными или
        биометрическими: — анкетные данные (фамилия, имя, отчество, число, месяц, год рождения и др.), адреса электронной почты,
        пользовательские данные (сведения о местоположении; тип и версия операционной системы (ОС), тип и версия Браузера, тип устройства и
        разрешение его экрана, информация о поисковых запросах Пользователя, источнике откуда пришел на сайт пользователь; с какого сайта
        или по какой рекламе; язык ОС и Браузера; история перехода по адресным ссылкам; ip-адрес), сведения о расчетных счетах и реквизитах
        платежных карт Пользователя, а также иные персональные данные необходимые Оператору для обработки в целях, для которых данное
        Согласие предоставляется Пользователем.
      </p>
      <p>
        9.3. Цель обработки персональных данных: обработка входящих запросов физических лиц для последующей связи в целях приобретения
        Пользователем (выбора, резервирования, заказа, оплаты, получения информации) товаров посредством сайта neg24.ru, аналитики действий
        физического лица на интернет-сайте neg24.ru функционирования и усовершенствования интернет-сайта neg24.ru.
      </p>
      <p>
        9.4. Основанием для обработки персональных данных является: ст. 24 Конституции Российской Федерации; ст.6 Федерального закона №
        152-ФЗ «О персональных данных» (далее Закон о персональных данных); локальные акты ООО «Негоциант».
      </p>
      <p>
        9.5. В ходе обработки Оператором персональных данных Пользователя будут совершены действия, предусмотренные п. 3 ст. 3 Федерального
        Закона № 152-ФЗ «О персональных данных, а именно: любое действие с персональными данными, включая сбор, запись, систематизацию,
        накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление,
        доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
      </p>
      <p>
        9.6. Персональные данные обрабатываются и хранятся бессрочно. Также обработка персональных данных может быть прекращена по запросу
        субъекта персональных данных. Хранение персональных данных, зафиксированных на бумажных носителях осуществляется согласно
        Федеральному закону № 125-ФЗ «Об архивном деле в Российской Федерации» и иным нормативно правовым актам в области архивного дела и
        архивного хранения.
      </p>
      <p>
        9.7. Согласие может быть отозвано субъектом персональных данных (Пользователем) или его представителем путем направления письменного
        заявления ООО «Негоциант» по адресу, указанному в настоящем Согласии.
      </p>
      <p>
        9.8. В случае отзыва субъектом персональных данных (Пользователем) или его представителем согласия на обработку персональных данных
        ООО «Негоциант» (Оператор) вправе продолжить обработку персональных данных без согласия субъекта персональных данных (Пользователя)
        при наличии оснований, указанных в пунктах 2 — 11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11 Федерального закона
        №152-ФЗ «О персональных данных» от 27.07.2006 г.
      </p>
      <p>
        9.9. Настоящее согласие действует все время до момента прекращения обработки персональных данных, указанных в пунктах 7 и 8
        настоящего Согласия.
      </p>
      <h3>10. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h3>
      <p>10.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.</p>
      <p>
        10.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте компании ООО «Негоциант», если иное не
        предусмотрено новой редакцией Политики конфиденциальности.
      </p>
      <p>10.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать на электронный адрес info@neg24.ru.</p>
      <p>10.4. Действующая Политика конфиденциальности размещена на странице по адресу neg24.ru.</p>
    </Container>
  );
}
