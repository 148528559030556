// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Spinner_spinner__qF1ex {\n  width: 20px;\n  height: 20px;\n  border: 2px solid #fff;\n  border-bottom-color: var(--accent);\n  border-radius: 50%;\n  display: inline-block;\n  box-sizing: border-box;\n  animation: Spinner_rotation__B\\+3MB 1s linear infinite;\n}\n\n@keyframes Spinner_rotation__B\\+3MB {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/Loaders/Spinner/Spinner.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,sBAAA;EACA,kCAAA;EACA,kBAAA;EACA,qBAAA;EACA,sBAAA;EACA,sDAAA;AACF;;AAEA;EACE;IACE,uBAAA;EACF;EACA;IACE,yBAAA;EACF;AACF","sourcesContent":[".spinner {\r\n  width: 20px;\r\n  height: 20px;\r\n  border: 2px solid #fff;\r\n  border-bottom-color: var(--accent);\r\n  border-radius: 50%;\r\n  display: inline-block;\r\n  box-sizing: border-box;\r\n  animation: rotation 1s linear infinite;\r\n}\r\n\r\n@keyframes rotation {\r\n  0% {\r\n    transform: rotate(0deg);\r\n  }\r\n  100% {\r\n    transform: rotate(360deg);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": "Spinner_spinner__qF1ex",
	"rotation": "Spinner_rotation__B+3MB"
};
export default ___CSS_LOADER_EXPORT___;
