import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { INews } from "../models/News";
import NewsService from "../services/NewsService";
import dateFormat from "dateformat";

export default function MainPage() {
  const [newsList, setNewsList] = useState<INews[]>([]);

  useEffect(() => {
    NewsService.getAll()
      .then((res) => {
        setNewsList(res.data);
      })
      .catch((e) => {
        if (e.response) {
          console.log(e.response.data);
        } else {
          console.log("Error", e.message);
        }
      });
  }, []);

  return (
    <Container className="main-page mt-5">
      <h1 className="mb-4">Новости</h1>
      {newsList.map((n) => (
        <Card className="p-5 rounded-3 mb-5" key={n.shopnewsid}>
          <p className="fs-6">Дата публикации: {dateFormat(new Date(n.publicationdate), "dd.mm.yyyy")}</p>
          {/* <h3>{n.title}</h3> */}
          <h3 className="mb-4" dangerouslySetInnerHTML={{ __html: n.body }} />
        </Card>
      ))}
    </Container>
  );
}
