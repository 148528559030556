import $api from "../axios/config.axios";
import { AxiosResponse } from "axios";
import { CharacteristicType } from "../types/characteristic";

interface INomenclatureItem {
  nomenclatureid: string;
  name: string;
  namefull: string;
  code: string;
  defaultmeasurenameshort: string;
  imageid: string;
  inventory: number;
  packagessetitemid: string;
  packagessetitemname: string;
  packagessetitemcount: number;
  price: number;
  groupcharacteristicid: string;
}
interface INomenclature {
  nomenclatureid: string;
  name: string;
  namefull: string;
  code: string;
  groupcharacteristicid: string;

  groupnomenclatureid: string;
  groupnomenclaturename: string;
  groupnomenclaturenamepath: string;

  price: number;
  inventory: number;
  packagessetid: string;
  packagessetname: string;
  packagessetitemid: string;
  description: string;

  defaultmeasureid: string;
  defaultmeasurenameshort: string;
}

interface INomenclatureCharacteristic {
  characteristicvalueid: null;
  characteristicrangetype: number | null;
  value1: string | null;
  value2: string | null;
  groupcharacteristicid: string;
  groupcharacteristicname: string;
  groupcharacteristicparentname: string;
  groupcharacteristicparentid: string;
  nomenclatureid: string | null;
  characteristicid: string;
  characteristicname: string;
  characteristicvaluetype: CharacteristicType;
  recid: string | null;
  recviewdata: string | null;
}

interface IGroupCharacteristic {
  groupcharacteristicid: string;
  parentid: string;
  name: string;
  namepath: string;
  imageid: string;
  imagename: string;
  haschild: boolean;
}

export default class NomenclatureService {
  static async getAllGroupCharacteristics(parentid?: string): Promise<AxiosResponse<IGroupCharacteristic[]>> {
    return $api.get<IGroupCharacteristic[]>(`groupcharacteristic`, { params: { parentid } });
  }

  static async getOne(
    nomenclatureid: string,
    placeid: string
  ): Promise<AxiosResponse<INomenclature & { characteristics: INomenclatureCharacteristic[] } & { images: INomenclatureItem[] }>> {
    return $api.get<INomenclature & { characteristics: INomenclatureCharacteristic[] } & { images: INomenclatureItem[] }>(
      `nomenclature/${nomenclatureid}`,
      { params: { placeid } }
    );
  }

  static async getOneGroupCharacteristic(groupcharacteristicid: string): Promise<AxiosResponse> {
    return $api.get<AxiosResponse>(`groupcharacteristic/${groupcharacteristicid}`);
  }

  static async getAllCharacteristics(groupcharacteristicid: string): Promise<AxiosResponse> {
    return $api.get<AxiosResponse>(`characteristic/${groupcharacteristicid}`);
  }
}
