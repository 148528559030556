import React from "react";
import { Container } from "react-bootstrap";
import emptyBox from "../../assets/img/empty-box.png";

export type EmptyBoxProps = {
  message?: string;
  children?: React.ReactNode;
};

const EmptyBox: React.FC<EmptyBoxProps> = ({ message, children }) => {
  if (message === undefined) message = "Пусто";
  return (
    <Container className="alert text-center rounded-4 my-5 text-b border border-5">
      <img src={emptyBox} className="p-5 " alt="none" />
      {message}
      {children}
    </Container>
  );
};

export default EmptyBox;
