import { create } from "zustand";
import { ShopPlaceService } from "../services/ShopPlaceService";
import config from "../config";

export type PlaceState = {
  placeid: string;
  name: string;
  namesys: string;
  comstructureid: string;
  currencyid: string;
  shopid: string;
  isdefault: boolean;
};

type State = {
  placeList: PlaceState[];
  place: PlaceState | null;
};

type Action = {
  getAll: () => Promise<PlaceState[]>;
  getOne: (placeid: string) => Promise<PlaceState>;
  getOneByName: (placename: string) => Promise<PlaceState>;
  getDefault: () => Promise<PlaceState>;
  updatePlace: (place: PlaceState) => void;
  deletePlace: () => void;
};

const usePlaceStore = create<State & Action>()((set, get) => ({
  placeList: [],
  place: null,

  updatePlace: (place) => {
    set({ place: place });
  },
  deletePlace: () => {
    set({ place: null });
  },

  getAll: async () => {
    const res = await ShopPlaceService.getAll();
    set({ placeList: res.data });
    if (res.data.length === 0) {
      window.localStorage.removeItem(`${config.API_SHOP}-placeid`);
      set({ place: null });
    }
    return res.data;
  },

  getOne: async (placeid) => {
    const place = await ShopPlaceService.getOne(placeid).then((res) => {
      set({ place: res.data });
      return res.data;
    });
    return place;
  },

  getOneByName: async (placename) => {
    const place = await ShopPlaceService.getOneByName(placename).then((res) => {
      set({ place: res.data });
      return res.data;
    });
    return place;
  },

  getDefault: async () => {
    const place = await ShopPlaceService.getOneDefault().then((res) => {
      set({ place: res.data });
      return res.data;
    });
    return place;
  },
}));
export default usePlaceStore;
