import { useEffect } from "react";
import createSocket from "../lib/socket";

export default function useSocket(onBillCreated: any) {
  useEffect(() => {
    const socket = createSocket();
    console.log({ onBillCreated });

    // Обработка события получения сообщения о готовности счета
    socket.on("pdf-created", onBillCreated);
    // Очистка при размонтировании
    return () => {
      socket.off("message", onBillCreated);
    };
  }, [onBillCreated]);

  useEffect(() => {
    const socket = createSocket();
    // Обработка события получения сообщения о готовности счета
    socket.on("pdf-error", onBillCreated);
    // Очистка при размонтировании
    return () => {
      socket.off("message", onBillCreated);
    };
  }, [onBillCreated]);
}
