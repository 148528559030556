import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";
import useCurrencyStore from "../../zustand/currency-store";
import useCartStore from "../../zustand/cart-store";
import PeopleOrder from "./BuyerOrder/PeopleOrder";
import OrganizationOrder from "./BuyerOrder/OrganizationOrder";
import useInvoiceStore from "../../zustand/invoice-store";
import useUserStore from "../../zustand/user-store";
import usePlaceStore from "../../zustand/place-store";
import config from "../../config";
import showToast from "../../utils/toast";
import { InvoiceBuyerType } from "../../types/invoice";

export function CartOrder() {
  const { place } = usePlaceStore((state) => state);
  const { user } = useUserStore((state) => state);
  const { invoiceState, init, getOne, setInvoiceState, updateBuyerType } = useInvoiceStore((state) => state);
  const [loading, setLoading] = useState<boolean>(false);

  const { currency } = useCurrencyStore((state) => state);
  const { cart } = useCartStore((state) => state);
  const [buyertype, setBuyer] = useState<InvoiceBuyerType>(InvoiceBuyerType.Personal);
  const totalCount = cart.reduce((sum, item) => sum + item.cnt, 0);
  const totalPrice = cart.reduce((a, b) => a + b.temporarysum * b.cnt, 0);

  useEffect(() => {
    if (!user.userprofileid) return;
    setLoading(true);
    const invoiceId = localStorage.getItem(`${config.API_SHOP}-invoiceid`);
    if (!invoiceId) {
      init()
        .then((invoiceid) => {
          localStorage.setItem(`${config.API_SHOP}-invoiceid`, invoiceid);
          getOne(invoiceid)
            .then((invoice) => {
              setInvoiceState(invoice);
            })
            .catch((e) => showToast("error", "Ошибка получения данных о заказе"));
        })
        .catch((e) => showToast("error", "Ошибка инициализации заказа"))
        .finally(() => setLoading(false));
    } else {
      getOne(invoiceId)
        .then((invoice) => setInvoiceState(invoice))
        .catch((e) => showToast("error", "Ошибка получения данных о заказе"))
        .finally(() => setLoading(false));
    }
  }, [user]);

  const changeBuyerHandler = (event: React.MouseEvent<HTMLButtonElement>, p: InvoiceBuyerType) => {
    event.stopPropagation();
    if (!invoiceState) return;
    updateBuyerType(invoiceState.invoiceid, p);
    setInvoiceState({ ...invoiceState, buyertype: p });
    setBuyer(p);
    return false;
  };

  return (
    <Container className="order-container text-b t-0">
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Row className="text-center align-items-center my-4">
            <Col xs={12} sm={7} md={6} lg={6} xl={6}>
              <Link className="d-flex align-items-center" to="/">
                <img width={70} height={70} src="/images/negotsiantlogo.png" className="logo" alt="LOGO" />
                <h2 className="logo-label fw-bold m-0">OOO "НЕГОЦИАНТ"</h2>
              </Link>
            </Col>
            <Col xs={12} sm={5} md={6} lg={3} xl={4}>
              <div className="align-items-center">
                <p className="mb-0">Оформляем {totalCount} товар(-ов, -а)</p>
                <p className="mb-0">
                  на сумму {totalPrice.toLocaleString()} {currency.nameshort}
                </p>
              </div>
            </Col>
          </Row>
          <hr />
          <h2 className="mt-5">Данные покупателя</h2>
          <Row className="mt-3 mb-5 align-items-center" role="group" aria-label="Базовая группа переключателей радио">
            <Col
              xxs={6}
              xs={6}
              md={6}
              lg={6}
              xl={6}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => changeBuyerHandler(event, InvoiceBuyerType.Personal)}
              className={classNames(
                "py-2 btn-buyer text-center rounded-0 ",
                {
                  "border-3 border-top border-end border-start rounded-top": buyertype === InvoiceBuyerType.Personal,
                },
                {
                  "border-3 border-bottom": buyertype === InvoiceBuyerType.Corporate,
                }
              )}
            >
              <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" defaultChecked />
              <label htmlFor="btnradio1">Физическое лицо</label>
            </Col>

            <Col
              xxs={6}
              xs={6}
              md={6}
              lg={6}
              xl={6}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => changeBuyerHandler(event, InvoiceBuyerType.Corporate)}
              className={classNames(
                "py-2 btn-buyer text-center rounded-0",
                {
                  "border-3 border-top border-start border-end rounded-top": buyertype === InvoiceBuyerType.Corporate,
                },
                {
                  "border-3 border-bottom": buyertype === InvoiceBuyerType.Personal,
                }
              )}
            >
              <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" />
              <label htmlFor="btn-check">Юридическое лицо</label>
            </Col>
          </Row>
          <div>
            {buyertype === InvoiceBuyerType.Personal && <PeopleOrder />}
            {buyertype === InvoiceBuyerType.Corporate && <OrganizationOrder />}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default CartOrder;
