// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Pagination_root__ELB4o li {\n  display: inline-block;\n}\n.Pagination_root__ELB4o li a {\n  text-align: center;\n  width: 42px;\n  line-height: 42px;\n  height: 42px;\n  border: 1px solid #00b4b6;\n  border-radius: 100px;\n  margin-right: 10px;\n  cursor: pointer;\n  display: inline-block;\n  color: #00b4b6 !important;\n}\n.Pagination_root__ELB4o li a:hover {\n  background-color: #00b4b6;\n  color: white !important;\n}\n.Pagination_root__ELB4o .selected a {\n  background-color: #00b4b6;\n  color: white !important;\n}", "",{"version":3,"sources":["webpack://./src/components/Pagination/Pagination.module.scss"],"names":[],"mappings":"AACE;EACE,qBAAA;AAAJ;AAEI;EACE,kBAAA;EACA,WAAA;EACA,iBAAA;EACA,YAAA;EAEA,yBAAA;EACA,oBAAA;EACA,kBAAA;EACA,eAAA;EACA,qBAAA;EACA,yBAAA;AADN;AAGM;EACE,yBAAA;EACA,uBAAA;AADR;AAQM;EACE,yBAAA;EACA,uBAAA;AANR","sourcesContent":[".root {\r\n  li {\r\n    display: inline-block;\r\n\r\n    a {\r\n      text-align: center; \r\n      width: 42px;\r\n      line-height: 42px;\r\n      height: 42px;\r\n\r\n      border: 1px solid #00b4b6;\r\n      border-radius: 100px;\r\n      margin-right: 10px;\r\n      cursor: pointer;\r\n      display: inline-block;\r\n      color: #00b4b6 !important;\r\n\r\n      &:hover {\r\n        background-color: #00b4b6;\r\n        color: white !important;\r\n      }\r\n    }\r\n  }\r\n\r\n  :global {\r\n    .selected {\r\n      a {\r\n        background-color: #00b4b6;\r\n        color: white !important;\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Pagination_root__ELB4o"
};
export default ___CSS_LOADER_EXPORT___;
