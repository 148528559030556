import classNames from "classnames";
import React from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import ChangeDeleteAccountItem from "./ChangeDeleteAccountItem";
import ChangeEmailItem from "./ChangeEmailItem";
import ChangePasswordItem from "./ChangePasswordItem";
import ChangePhoneItem from "./ChangePhoneItem";
import ChangeUserDataItem from "./ChangeUserDataItem";

const UserProfilePage: React.FC = () => {
  enum Page {
    userdata,
    phone,
    email,
    password,
    accdelete,
  }

  const [page, setPage] = React.useState<Page>(Page.userdata);

  const changePageHandler = (event: React.MouseEvent<HTMLButtonElement>, p: Page) => {
    event.stopPropagation();
    setPage(p);
    return false;
  };

  return (
    <Container className="user-settings-container mt-5 p-0">
      <div className="d-none d-md-block">
        <h1 className="ps-4">Параметры аккаунта</h1>
        <hr className="text-dark" />
      </div>
      <Row className="w-100">
        <Col xs={4} className="p-0" style={{ backgroundColor: "#00b4b6", color: "white", flex: "bottom", height: "calc(1000px - 120px)" }}>
          <Nav
            className="m-0 px-0 fs-5"
            style={{
              listStyle: "none",
              lineHeight: "50px",
              color: "white",
            }}
          >
            <Nav.Link
              className={classNames("param-item col-12", {
                "list-link-active": page === Page.userdata,
              })}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => changePageHandler(event, Page.userdata)}
            >
              Имя
            </Nav.Link>

            <Nav.Link
              className={classNames("param-item col-12", {
                "list-link-active": page === Page.phone,
              })}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => changePageHandler(event, Page.phone)}
            >
              Телефон
            </Nav.Link>

            <Nav.Link
              className={classNames("param-item col-12", {
                "list-link-active": page === Page.email,
              })}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => changePageHandler(event, Page.email)}
            >
              Адрес электронной почты
            </Nav.Link>

            <Nav.Link
              className={classNames("param-item col-12", {
                "list-link-active": page === Page.password,
              })}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => changePageHandler(event, Page.password)}
            >
              Пароль
            </Nav.Link>

            <Nav.Link
              className={classNames("param-item col-12", {
                "list-link-active": page === Page.accdelete,
              })}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => changePageHandler(event, Page.accdelete)}
            >
              Удалить аккаунт
            </Nav.Link>
          </Nav>
        </Col>

        <Col xs={8} className="p-4" style={{ color: "#3e4652" }}>
          {page === Page.userdata && <ChangeUserDataItem />}
          {page === Page.phone && <ChangePhoneItem />}
          {page === Page.email && <ChangeEmailItem />}
          {page === Page.password && <ChangePasswordItem />}
          {page === Page.accdelete && <ChangeDeleteAccountItem />}
        </Col>
      </Row>
    </Container>
  );
};

export default UserProfilePage;
