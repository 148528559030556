import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import config from "../../config";
import nomenclatureNoImg from "../../assets/img/nomenclature_no_image.png";

type ShowCaseDownloadItemProps = {
  showcasedownloadid: string;
  productversionid: string;
  productid: string;
  architecture: number;
  productname: string;
  versionmajor: number;
  versionminor: number;
  versionrelease: number;
  versionbuild: number;
  activity: boolean;
  urldownload: string;
  urlpage: string;
  imageid: string;
  description: any;
  isright: boolean;
};

const ShowCaseDownloadItem: React.FC<ShowCaseDownloadItemProps> = ({
  isright,
  productname,
  versionmajor,
  versionminor,
  versionrelease,
  versionbuild,
  imageid,
  urlpage,
  urldownload,
  description,
}) => {
  function productDescription() {
    if (description) return new TextDecoder("utf-8").decode(new Uint8Array(description.data));
    return "";
  }
  return (
    <>
      <div className="d-none d-md-block ">
        {isright && (
          <Row>
            <Col xs={12} sm={12} md={6} lg={4} xl={3}>
              <img className="w-100 p-5" src={imageid ? `${config.API_URL}/nomenclature/image/` + imageid : nomenclatureNoImg} alt="icon" />
            </Col>

            <Col className="p-5 text-end">
              <p dangerouslySetInnerHTML={{ __html: productDescription() }}></p>
              <a href={`${config.API_URL}/showcase-download/download/${config.API_SHOP}/${urldownload}`} download>
                <Button className="fs-4 style-btn-action py-2 px-4">Скачать</Button>
              </a>
            </Col>
          </Row>
        )}
        {!isright && (
          <Row>
            <Col className="p-5 text-start">
              <p dangerouslySetInnerHTML={{ __html: productDescription() }}></p>
              <a
                href={`${config.API_URL}/showcase-download/download/${config.API_SHOP}/${urldownload}`}
                target="_blank"
                download
                rel="noreferrer"
              >
                <Button className="fs-4 style-btn-action py-2 px-4">Скачать</Button>
              </a>
            </Col>

            <Col xs={12} sm={12} md={6} lg={4} xl={3}>
              <img className="w-100 p-5" src={imageid ? `${config.API_URL}/nomenclature/image/` + imageid : nomenclatureNoImg} alt="icon" />
            </Col>
          </Row>
        )}
      </div>
      <div className="d-md-none">
        <img className="w-100 p-5" src={imageid ? `${config.API_URL}/nomenclature/image/` + imageid : nomenclatureNoImg} alt="icon" />
        <p dangerouslySetInnerHTML={{ __html: productDescription() }}></p>
        <a href={`${config.API_URL}/download/${urldownload}`} download>
          <Button className="fs-4 style-btn-action py-2 px-4">Скачать</Button>
        </a>
      </div>
    </>
  );
};

export default ShowCaseDownloadItem;
