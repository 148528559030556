import { AxiosResponse } from "axios";
import api from "../axios/config.axios";

interface ICartItem {
  cartid: string;
  cnt: number;
  temporarysum: number;
  price: number;
  isexists: boolean;

  nomenclatureid: string;
  nomenclaturename: string;
  nomenclaturenamefull: string;
  nomenclaturecode: string;
  nomenclatureimageid: string;

  packagessetitemid: string;
  packagessetitemname: string;
}

export default class CartService {
  // Получение списка все элементв корзины
  static async getAll(): Promise<AxiosResponse<ICartItem[]>> {
    return await api.get<ICartItem[]>(`cart`);
  }

  static async check(): Promise<AxiosResponse<boolean>> {
    return await api.get<boolean>(`cart/check`);
  }

  static async insert(nomenclatureid: string): Promise<AxiosResponse<ICartItem>> {
    return await api.post<ICartItem>(`cart`, { nomenclatureid });
  }

  static async increment(cartid: string): Promise<AxiosResponse<void>> {
    return await api.put<void>(`cart/increment`, { cartid });
  }

  static async decrement(cartid: string): Promise<AxiosResponse<void>> {
    return await api.put<void>(`cart/decrement`, { cartid });
  }

  static async setCount(cartid: string, count: number): Promise<AxiosResponse<void>> {
    return await api.put<void>(`cart/count`, { cartid, count });
  }

  static async delete(cartid: string): Promise<AxiosResponse<void>> {
    return await api.delete<void>(`cart`, { params: { cartid } });
  }

  static async clear(): Promise<AxiosResponse<void>> {
    return await api.delete<void>(`cart/clear`);
  }
}
