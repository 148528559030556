import { AxiosResponse } from "axios";
import {
  InvoiceAssemblyStatusType,
  InvoiceBuyerType,
  InvoicePaymentMethod,
  InvoicePaymentStatusType,
  InvoicePaymentType,
  InvoicePickDeliveryMethod,
  InvoicePickType,
  TimeWork,
} from "../types/invoice";
import api from "../axios/config.axios";

interface IInvoiceEx {
  invoiceid: string;
  invoicenumber: number;

  buyerid: string;
  buyertype: InvoiceBuyerType;
  buyerorganizationid: string;
  organizationid: string;
  organizationname: string;

  currencyid: string;
  currencyname: string;
  currencynamesys: string;
  currencynameshort: string;

  purposepartnerid: string;
  purposepartnername: string;

  comment: string;

  paymentid: string;

  invoicepaymentstatusid: string;
  invoicepaymentstatusname: string;
  invoicepaymentstatustype: InvoicePaymentStatusType;

  cnt: number;
  price: number;

  paymenttype: InvoicePaymentType;
  paymentmethod: InvoicePaymentMethod;

  assemblystatusid: string;
  assemblystatusname: string;
  assemblystatustype: InvoiceAssemblyStatusType;

  recorddatecreate: Date;
}

interface IGoodsInvoice {
  goodsinvoiceid: string;
  deliverytype: InvoicePickType;
  deliverymethod: InvoicePickDeliveryMethod;
  deliverystatusid: string;
  peopledeliveryaddressid: string;
  organizationdeliveryaddressid: string;
}

interface IInvoiceItem {
  invoiceitemid: string;
  invoiceid: string;
  cnt: number;
  price: number;
  measurename: string;
  packagessetitemid: string;
  packagessetitemname: string;
  nomenclatureid: string;
  nomenclaturename: string;
  nomenclaturenamefull: string;
  images: { nomenclatureimageid: string; nomenclatureid: string; name: string; imageid: string }[];
}

interface IPickUpPoint {
  pickuppointid: string;
  name: string;
  warehouseid: string;
  placeid: string;
  placename: string;
}

interface IInvoiceHistory {
  invoiceId: string;
  invoiceNumber: number;
  invoiceAmount: number;
  invoiceStatus: number;
  invoiceCreationDate: Date;
  buyer: {
    buyerType: number;
    buyerName: string;
  };
  payment: {
    paymentId: string;
    paymentType: number;
    paymentMethod: number;
    paymentStatusId: string;
    paymentStatusType: number;
    paymentStatusName: string;
  };
  assembly: {
    assemblyStatusId: string;
    assemblyStatusType: number;
    assemblyStatusName: string;
  };
  delivery: {
    deliveryId: string;
    deliveryType: number;
    deliveryMethod: number;
    deliveryStatusId: string;
    deliveryStatusType: number;
    deliveryStatusName: string;
    deliveryAmount: number | null;
    address: {
      countryid: string | null;
      countryname: string | null;
      regionid: string | null;
      regionname: string | null;
      districtid: string | null;
      districtname: string | null;
      cityid: string | null;
      cityname: string | null;
      streetid: string | null;
      streetname: string | null;
      buildingid: string | null;
      buildingname: string | null;
      symbol: string | null;
      apartment: string | null;
    };
  };
}
export class InvoiceService {
  static async getAllHistory(
    status: number,
    offset: number,
    limit: number
  ): Promise<AxiosResponse<{ items: IInvoiceHistory[]; totalCount: number }>> {
    return await api.get<{ items: IInvoiceHistory[]; totalCount: number }>(`invoice-history`, { params: { status, offset, limit } });
  }

  static async getAllItems(invoiceid: string): Promise<AxiosResponse<IInvoiceItem[]>> {
    return api.get<IInvoiceItem[]>(`invoice/items/${invoiceid}`);
  }

  static async getOne(invoiceid: string): Promise<AxiosResponse<IInvoiceEx & IGoodsInvoice>> {
    return api.get<IInvoiceEx & IGoodsInvoice>(`invoice/${invoiceid}`);
  }

  static async initInvoice(): Promise<AxiosResponse<string>> {
    return api.post<string>(`invoice/init`);
  }
  static async create(invoiceid: string, placeid: string): Promise<AxiosResponse<any>> {
    return api.post<any>(`invoice/create`, { invoiceid, placeid });
  }

  //// Buyer
  static async updateBuyerType(invoiceid: string, buyerType: InvoiceBuyerType): Promise<AxiosResponse<void>> {
    return api.put(`invoice/buyer`, { invoiceid, buyerType });
  }
  static async updateBuyerOrganization(invoiceid: string, organizationid: string): Promise<AxiosResponse<void>> {
    return api.put(`invoice/buyer/organization`, { invoiceid, organizationid });
  }

  //// Pick
  static async getInvoiceDeliveryInfoByInvoice(invoiceid: string): Promise<AxiosResponse<any>> {
    return api.get<any>(`invoice-delivery-info/${invoiceid}`);
  }
  static async setInvoiceDeliveryinfo(
    invoiceid: string,
    deliveryinvoiceid: string | null,
    officeid: string | null,
    expectedDeliveryDate: Date | null,

    totalSum: number, // предлагаю удалить
    weight: number, // предлагаю удалить
    calendarMin: number, // предлагаю удалить
    calendarMax: number // предлагаю удалить
  ): Promise<AxiosResponse<void>> {
    return api.put<void>(`invoice-delivery-info`, {
      invoiceid,
      deliveryinvoiceid,
      officeid,
      expectedDeliveryDate,
      totalSum,
      weight,
      calendarMin,
      calendarMax,
    });
  }

  static async getAllSelfPickUpPoint(placeid: string): Promise<AxiosResponse<IPickUpPoint[]>> {
    return api.get<IPickUpPoint[]>(`invoice-pick/self/points`, { params: { placeid } });
  }
  static async getOneSelfPickUpPointByInvoice(invoiceid: string): Promise<AxiosResponse<IPickUpPoint & { timework: TimeWork }>> {
    return api.get<IPickUpPoint & { timework: TimeWork }>(`invoice-pick/self/${invoiceid}`);
  }
  static async updatePickType(invoiceid: string, pickType: InvoicePickType): Promise<AxiosResponse<void>> {
    return api.put(`invoice-pick`, { invoiceid, pickType });
  }
  static async updatePickSelfPickUp(invoiceid: string, pickuppointid: string): Promise<AxiosResponse<void>> {
    return api.put(`invoice-pick/self`, { invoiceid, pickuppointid });
  }
  static async updatePickPickUpPoint(invoiceid: string, pickuppointid: string): Promise<AxiosResponse<void>> {
    return api.put(`invoice-pick/pvz`, { invoiceid, pickuppointid });
  }
  static async updatePickDeliveryAddress(invoiceid: string, addressid: string): Promise<AxiosResponse<void>> {
    return api.put(`invoice-pick/delivery`, { invoiceid, addressid });
  }

  //// Payment
  static async updatePaymentType(invoiceid: string, paymentType: InvoicePaymentType): Promise<AxiosResponse<void>> {
    return api.put(`invoice-payment`, { invoiceid, paymentType });
  }
  static async updatePaymentOnlineMethod(invoiceid: string, paymentMethod: InvoicePaymentMethod): Promise<AxiosResponse<void>> {
    return api.put(`invoice-payment/service`, { invoiceid, paymentMethod });
  }

  // Bill
  static async downloadBill(invoicenumber: string): Promise<AxiosResponse> {
    return api.get(`invoice/bill/${invoicenumber}`);
  }
  static async createBill(invoiceid: string) {
    return api.post(`invoice/bill`, { invoiceid });
  }
}
