import cn from "classnames";
import s from "./WaterWaveRing.module.scss";

export default function WaterWaveRing() {
  return (
    <div className={cn("mt-5 mx-auto", s.loader)}>
      <div></div>
      <div></div>
    </div>
  );
}
